class PageSlot {

    constructor({name, selector}) {
        this.name = name;
        this.selector = selector;
        this.element = null;
		this.currentPage = null;
    }


    getName() {
        return this.name;
    }


    getElement() {
        if (!this.element || !document.body.contains(this.element)) {
            this.element = document.querySelector(this.selector);
        }
        return this.element;
    }


	setElement(element) {
		this.element = element;
		return this;
	}


	getCurrentPage() {
		return this.currentPage;
	}


	setCurrentPage(page) {
		if (this.currentPage) {
			this.detach(this.currentPage.getElement());
		}
		this.currentPage = page;
		return this;
	}


	attach(element) {
		const parent = this.getElement();
		if (!this.currentPage || this.currentPage.getElement() !== element) {
			const firstChild = parent.firstChild;
			if (firstChild) {
				parent.insertBefore(element, firstChild);
			} else {
				parent.appendChild(element);
			}
		}
        return this;
	}


	detach(element) {
		const parent = this.getElement();
		if (parent === element.parentNode) {
			parent.removeChild(element);
		}
		return this;
	}

}


export default PageSlot;
