import PageComponent from '../../global/page/page-component';


class ArtistVideo extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {}, animationDuration} = {}) {
		super({context: context, selector: selector, config: config, status: status});
        this.animationDuration = animationDuration;
		this.videoPlayerDataSelector = 'video-player';
		this.videoVisible = false;
	}


    prepare(element, done) {
        this.clickListener = this.events.on(element, this.dataSelector('video-btn'), 'click', this.onClickListener.bind(this));
		this.player = element.querySelector(this.dataSelector(this.videoPlayerDataSelector));
        done();
    }


	clear(done) {
		this.clickListener.destroy();
		done();
	}


	onClickListener(event) {
		if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
			event.preventDefault();

			if (!this.videoVisible) {
				this.classList(this.element).add('visiblePlayer');
				this.player.innerHTML = this.dataAttr(this.player).get(this.videoPlayerDataSelector);
				this.videoVisible = true;
			}
		}
	}
}


export default ArtistVideo;
