import PageComponent from './page-component';
import pageComponentCollectionMixin from './page-component-collection-mixin';


class PageComponentCollection extends pageComponentCollectionMixin(PageComponent) {


	init(element, done = () => {}) {
		super.init(element, () => {
			this.status.ready = false;
			this.initComponents(() => {
				this.status.ready = true;
				done();
			});
		});
		return this;
	}


	deinit(done = () => {}) {
		super.deinit(() => {
			this.status.ready = true;
			this.deinitComponents(() => {
				this.status.ready = false;
				done();
				this.components.clear();
			});
		});
		return this;
	}


	activate(force = false) {
		if (!this.status.active) {
			super.activate(force);
			this.activateComponents(force);
		}
		return this;
	}


	deactivate() {
		if (this.status.active) {
			super.deactivate();
			this.deactivateComponents();
		}
		return this;
	}

}


export default PageComponentCollection;
