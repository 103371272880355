import Factory from '../di/factory';


class VideoFactory extends Factory {


    newInstance(element, params = {}) {
        params.element = element;
        return super.newInstance('Video', params);
    }

}

export default VideoFactory;
