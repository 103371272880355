import {ucFirst} from '../utils/string';


class Factory {

	constructor({container, prefix = '', suffix = ''}) {
        this.container = container;
		this.prefix = prefix;
		this.suffix = suffix;
    }


    newInstance(type, params) {
        return this.container.newInstance(this.prefix + ucFirst(type) + this.suffix, params);
    }
}

export default Factory;
