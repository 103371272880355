import {camelize, decamelize} from './string';
import {isObject, isArray} from './types';

// very simplified tool to read data attributes

function encodeValue(val) {
    if (isObject(val) || isArray(val)) return JSON.stringify(val);
    if (val === true) return 'true';
    if (val === false) return 'false';
    if (val === null) return 'null';
    return val + '';
}


// inspired by jquery data attributes parsing
function decodeValue(val) {
    if (typeof val === 'string') {
        if (val === 'true') return true;
        if (val === 'false') return false;
        if (val === 'null') return null;

        try {
            // Only convert to a number if it doesn't change the string
            if (+val + '' === val) return +val;
            if (/^(?:\{[\w\W]*\}|\[[\w\W]*\])$/.test(val)) return JSON.parse(val);
            return val;
        } catch (e) {
            return val;
        }
    }
    return undefined;
}


function remove(element, name) {
    if (element) {
        const attrName = 'data-' + decamelize(name);
        element.removeAttribute(attrName);
    }
}


const data = function(element, name, value) {
    if (!(element instanceof Element)) {
        return undefined;
    }
    let attrName;
    let result = undefined;
    if (name !== undefined) {
        attrName = 'data-' + decamelize(name);

        if (value !== undefined) {
            // set
            value = encodeValue(value);
            element.setAttribute(attrName, encodeValue(value));
        } else if(element.hasAttribute(attrName)) {
            // get single
            result = decodeValue(element.getAttribute(attrName));
        }
    } else {
        // get all
        result = {};
        let attr;
        for (let i = 0, end = element.attributes.length; i < end; i++) {
            attr = element.attributes[i];
            if (attr.name.indexOf('data-') === 0) {
                attrName = camelize(attr.name.substr(5));
                result[attrName] = decodeValue(attr.value);
            }
        }
    }
    return result;
};

data.encodeValue = encodeValue;
data.decodeValue = decodeValue;
data.remove = remove;


export default data;
