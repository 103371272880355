import DefaultPage from './default-page';

class ArtistPage extends DefaultPage {

	prepare(element, done) {
		super.prepare(element, () => {
			this.addNewComponent('ExclusionMenu');
			this.addNewComponent('ContentShifter');
			const videos = element.querySelectorAll(this.dataSelector('video'));
			for (const video of videos) {
				this.addNewComponent('artistVideo').setElement(video);
			}
			done();
		});
	}

}

export default ArtistPage;
