import {getScrollTop} from 'get-scroll';
import PageComponent from '../../global/page/page-component';
import {getViewportWidth} from '../../global/utils/size';

class MainMenu extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {}, animationDuration} = {}) {
		super({context: context, selector: selector, config: config, status: status});
    this.animationDuration = animationDuration;
		this.lastScrollTop = 0;
		this.mobileHideClass = 'mobileHide';
		this.isMobileHide = false;
		this.openClass = 'mainMenuOpen';
		this.MIN_ROLLOVER_WIDTH = 1000;
		this.LEFT_AUTOOPEN_MARGIN = 50;
		this.isOpened = false;
		this.isClosedByBtn = false;
	}


  prepare(element, done) {
		this.menuClickListener = this.events.on(this.element, this.dataSelector('menu-btn'), 'click', this.onMenuClick.bind(this));
		this.linkClickListener = this.events.on(this.element, this.dataSelector('menu-link'), 'click', this.onLinkClick.bind(this));
		this.scrollListener = this.events.on(window, 'window:scroll window:resize', this.onScroll.bind(this));
		this.historyListener = this.events.on(document, 'history:navigate', this.onNavigate.bind(this));
		//TODO click listener for links to close menu
		//TODO laterclick outside (transparent 100%height layer) close menu

		this.menuMouseMoveListener = this.events.on(document, 'mousemove', this.onMouseMove.bind(this));

		this.headerHight = element.offsetHeight;
		done();
  }


  clear(done) {
		this.menuClickListener.destroy();
		this.linkClickListener.destroy();
		this.scrollListener.destroy();
		this.historyListener.destroy();
		done();
  }

	onScroll(event) {
		const scrollTop = getScrollTop();
		const delta = scrollTop -  this.lastScrollTop;
		const direction = delta < 0?-1:1;

		if(Math.abs(delta) < 15) {
			return;
		}

		if (scrollTop > this.headerHight) {
			if(direction<0) {
				this.showMobile();
			} else {
				this.hideMobile();
			}
			//this.classList(document.body).toggle(this.mobileHideClass, direction>0);
		} else {
			this.showMobile();
			this.classList(document.body).remove(this.mobileHideClass);
		}
		this.lastScrollTop = getScrollTop();
	}

	hideMobile() {
		this.isMobileHide = true;
		this.classList(document.body).add(this.mobileHideClass);
	}

	showMobile() {
		this.isMobileHide = false;
		this.classList(document.body).remove(this.mobileHideClass);
	}

	onMenuClick(event) {
		if(this.isOpened) {
			this.isClosedByBtn = true;
			this.hideMenu();
		} else {
			this.showMenu();
		}
		this.classList(document.body).remove(this.mobileHideClass);
		event.preventDefault();
	}

	onLinkClick(event) {
	//	this.classList(document.body).remove(this.openClass);
	}

	onNavigate(event) {
		this.isClosedByBtn = true;
		this.hideMenu();
	}

	showMenu() {
		this.isOpened = true;
		this.classList(document.body).add(this.openClass);
	}

	hideMenu() {
		this.isOpened = false;
		this.classList(document.body).remove(this.openClass);
	}

	onMouseMove(event) {
		event.preventDefault();
		if(getViewportWidth() > this.MIN_ROLLOVER_WIDTH) {
			if(event.clientY < this.headerHight) {
				if(!this.isClosedByBtn && event.clientX > this.LEFT_AUTOOPEN_MARGIN && !this.isMobileHide) {
					this.showMenu();
				}
			} else {
				this.isClosedByBtn = false;
				this.hideMenu();
			}
		}
	}
}


export default MainMenu;
