import keymaster from 'keymaster';
import asq from 'asynquence';
import {isString} from '../utils/types';
import pageInteractionMixin from '../utils/page-interaction-mixin';


class ContextManager extends pageInteractionMixin() {

    constructor({element = document.body, defineKeyContext = true, popOnKey = 'esc'} = {}) {
		super();
		this.element = element;
        this.defineKeyContext = defineKeyContext;
		this.popOnKey = popOnKey;

		this.contexts = new Map();
        this.currentContext = null;
        this.defaultContext = null;
        this.stack = [];

        this.busy = false;
        if (popOnKey !== false) {
            keymaster(popOnKey, 'all', this.popByKey.bind(this));
        }
    }


	setContextFactory(contextFactory) {
		this.contextFactory = contextFactory;
	}


    addContext(context) {
        this.contexts.set(context.getName(), context);
        context.setContextManager(this);
        return this;
    }


	addNewContext(name, params = {}) {
		const context = this.contextFactory.newInstance(name, params);
		this.addContext(context);
		return context;
	}


    getContext(name) {
        if (this.contexts.has(name)) {
            return this.contexts.get(name);
        }
        return null;
    }


    setDefaultContext(context, activate = true) {
        if (isString(context)) {
            context = this.contexts.get(context);
        }
        this.currentContext = context;
        this.defaultContext = context;
        this.updateElement();
        if (this.defineKeyContext) {
            keymaster.setScope(context.getName());
        }
		if (activate) {
			context.activate();
		}
        return this;
    }


    getDefaultContext() {
        return this.defaultContext;
    }


    getCurrentContext() {
        return this.currentContext;
    }


	getContexts() {
		return this.contexts;
	}


    push(newContext, done = () => {}) {
        if (!this.busy) {
            if (isString(newContext)) {
                newContext = this.contexts.get(newContext);
            }
            if (newContext.getName() !== this.currentContext.getName()) {
                this.stack.push(this.currentContext);
                this.switch(this.currentContext, newContext, done);
            } else {
				done();
			}
        } else {
			done();
		}
        return this;
    }


    pop(done = () => {}) {
        if (!this.busy && this.stack.length) {
            const prev = this.stack.pop();
            this.switch(this.currentContext, prev, done);
        } else {
			done();
		}
        return this;
    }


	popByKey() {
		this.pop();
	}


    switch(currentContext, newContext, allDone = () => {}) {
        const seq = asq();
        this.busy = true;
        seq
            .then((done) => {
                newContext.beforeSwitchIn(done, currentContext);
            })
            .then((done) => {
                currentContext.beforeSwitchOut(done, newContext);
            })
            .then((done) => {
                currentContext.switchOut(done, newContext);
            })
            .then((done) => {
                newContext.switchIn(done, currentContext);
            })
            .then((done) => {
                newContext.afterSwitchIn(done, currentContext);
            })
            .then((done) => {
                currentContext.afterSwitchOut(done, newContext);
            })
            .then(() => {
                this.currentContext = newContext;
                if (this.defineKeyContext) {
                    keymaster.setScope(this.currentContext.getName());
                }
                this.busy = false;
                this.updateElement();
				currentContext.deactivate();
				newContext.activate();
                this.events.trigger(this.element, 'context:switchcomplete', {
                    contextManager: this,
                    previousContext: currentContext,
                    currentContext: isString(newContext) ? this.getContext(newContext) : newContext
                });
                allDone();
            });
        return this;
    }


    updateElement() {
        this.dataAttr(this.element).set('currentContext', this.currentContext.getName());
        return this;
    }

}


export default ContextManager;
