import NavigationTracker from './navigation-tracker';


class GaGtagNavigationTracker extends NavigationTracker {


	init() {
		// eslint-disable-next-line piggyback/no-restricted-global-extend
		this.tracker = ('gtag' in window ? window.gtag : null);
		if (this.tracker) {
			// eslint-disable-next-line piggyback/no-restricted-global-extend
			this.trackingId = window.gtagTrackingId;
		}
	}


	doTrack(url, title) {
		if (this.tracker) {
			console.log('tracking', url, title);
			this.tracker('config', this.trackingId, {
				page_title: title,
				page_location: url
			});
		} else {
			console.log('page change:', url, title);
		}
	}


	doTrackEvent(category, action, name = null, value = null) {
		// if (this.tracker) {
		// 	const params = ['trackEvent', category, action];
		// 	if (name !== null) {
		// 		params.push(name);
		// 	}
		// 	if (value !== null) {
		// 		params.push(value);
		// 	}
		// 	this.tracker.push(params);
		// } else {
			console.log('tracking event', category, action, name, value);
		// }
	}

}


export default GaGtagNavigationTracker;
