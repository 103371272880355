import PageComponent from '../../global/page/page-component';
import random from '../../global/utils/random';

class ColorsChanger extends PageComponent {

  prepare(element, done) {
    this.COLORS_NO = 15;
    this.CHANGE_INTERVAL = 5;
    this.currentClass = null;
    this.className = 'colorSet';

    this.change();
    //this.intervalId = setInterval(this.change.bind(this), this.CHANGE_INTERVAL * 1000);

    done();
  }

  clear(done) {
    //clearInterval(this.intervalId);
    done();
  }

  change() {
    //this.artistList = document.body.querySelector(this.dataSelector('artistList'));
    //if(this.artistList) this.artistList.style.transitionDuration  = this.CHANGE_INTERVAL + 's';

    if(this.currentClass) {
      this.classList(document.body).remove(this.currentClass);
    }
    const newId = random(1, this.COLORS_NO);
    this.currentClass = this.className + newId;
    this.classList(document.body).add(this.currentClass);
  }
}

export default ColorsChanger;
