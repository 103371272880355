import PageComponent from '../../global/page/page-component';

class ContentShifter extends PageComponent {


	//this class pushes down content, so it's always visible under
	//absolute positioned menu, no matter of it's height

    prepare(element, done) {
		this.content = element.nextElementSibling;
		this.onResize();
		this.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));
        done();
    }

	onResize(e) {
		this.content.style.paddingTop = this.element.offsetHeight + 'px';
	}

    clear(done) {
		this.resizeListener.destroy();
        done();
    }
}

export default ContentShifter;
