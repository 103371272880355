import imagesLoaded from 'imagesloaded';
import Slide from './Slide';


class ImageSlide extends Slide {

    constructor({slideshow, element, index, type = 'image', subtype, duration}) {
		super({slideshow: slideshow, element: element, index: index, type: type, subtype: subtype, duration: duration});
    }


    load() {
		// we should wait until the image src is ready (dut to async load and responsive setup)
		const load = () => {
			const img = this.element.querySelector('img');
			if (img.currentSrc && img.currentSrc.length) {
				imagesLoaded([img], () => {
		            super.load();
		        });
			} else {
				setTimeout(load, 20);
			}
		};
		load();
        return this;
    }


    getImage() {
        return this.element.querySelector('img');
    }

}

export default ImageSlide;
