const pageInteractionMixin = (Base = null) => class extends Base {

	setEvents(events) {
		this.events = events;
	}


	setClassList(classList) {
		this.classListGenerator = classList;
		this.classListService = classList();
	}


	setDataAttr(dataAttr) {
		this.dataAttrGenerator = dataAttr;
		this.dataAttrService = dataAttr();
	}


	setPrefix(prefix) {
		this.prefix = prefix;
	}


	setClassPrefix(classPrefix) {
		this.classPrefix = classPrefix;
	}


	classList(element = null, prefix = undefined) {
		if (prefix !== undefined) {
			return this.classListGenerator(element, prefix);
		}
		return this.classListService.setElement(element);
	}


	dataAttr(element = null, prefix = undefined) {
		if (prefix !== undefined) {
			return this.dataAttrGenerator(element, prefix);
		}
		return this.dataAttrService.setElement(element);
	}


	dataSelector(name, value = undefined) {
		return this.dataAttrService.getSelector(name, value);
	}


	classSelector(name) {
		return this.classListService.getSelector(name);
	}

};


export default pageInteractionMixin;
