// //import {getScrollTop} from 'get-scroll';
import PageTransition from '../../global/page/page-transition';


class DefaultPageTransition extends PageTransition {

	setLoaderAnimation(loaderAnimation) {
		this.loaderAnimation = loaderAnimation;
	}

	beginAnimation(done) {
		this.loaderAnimation.play(done, {cover: true});
	}


	endAnimation(done) {
		this.loaderAnimation.pause(done);
	}


}


export default DefaultPageTransition;
