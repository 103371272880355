import DefaultPage from './default-page';


class ExhibitionPage extends DefaultPage {

	prepare(element, done) {
		super.prepare(element, () => {
			this.addNewComponent('searchExhibitions');
			this.addNewComponent('basicSlideshow');
			done();
		});
	}

}

export default ExhibitionPage;
