import Factory from '../di/factory';


class ProgressAnimatorFactory extends Factory {

	constructor({container, prefix = '', suffix = 'ProgressAnimator'}) {
		super({container: container, prefix: prefix, suffix: suffix});
    }

}

export default ProgressAnimatorFactory;
