import {TweenMax} from 'gsap';
import PageComponent from '../../global/page/page-component';


class Accordion extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {}, transitionDuration = 0.4} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.transitionDuration = transitionDuration;
	}


	prepare(element, done) {
        this.collapseListener = this.events.on(element, this.dataSelector('buttonMode', 'collapse'), 'click', this.onCollapseClick.bind(this), {capture: false});
		done();
    }


    clear(done) {
        this.collapseListener.destroy();
        done();
    }


	onCollapseClick(event, target) {
		if (event.target.closest('a[href]') !== null) {
			return;
		}

		const item = target.closest(this.dataSelector('accordionItem'));
		const collapsed = this.dataAttr(item).get('collapsed');
		event.stopPropagation();
		if (collapsed) {
			this.openItem(item);
		} else {
			this.closeItem(item);
		}
		this.dataAttr(item).set('collapsed', !collapsed);
    }


    openItem(item) {
        const body = item.querySelector(this.dataSelector('accordionItemBody'));
        const currentHeight = body.offsetHeight;
        const content = body.querySelector(this.dataSelector('accordionItemContent'));
        const contentHeight = content.offsetHeight;
        TweenMax.fromTo(body, this.transitionDuration, {height: currentHeight + 'px'}, {
            height: contentHeight + 'px',
            ease: 'Power2.easeOut',
            onComplete: () => {
                // body.style.removeProperty('height');
            }
        });
    }


    closeItem(item) {
        const body = item.querySelector(this.dataSelector('accordionItemBody'));
        const currentHeight = body.offsetHeight;
        TweenMax.fromTo(body, this.transitionDuration, {height: currentHeight + 'px'}, {
            height: 0,
            ease: 'Power2.easeOut',
            onComplete: () => {
                body.style.removeProperty('height');
            }
        });
    }

}


export default Accordion;
