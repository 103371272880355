import PageComponent from '../page/page-component';


class Videos extends PageComponent {
    setVideoFactory(videoFactory) {
        this.videoFactory = videoFactory;
    }


    prepare(element, done) {
		this.videos = [];
        for (const videoElement of element.querySelectorAll('[data-media="video"]')) {
            this.videos.push(this.videoFactory.newInstance(videoElement));
        }
        done();
    }


    clear(done) {
        for (const video of this.videos) {
            video.deinit();
        }
        this.videos = null;
        done();
    }
}

export default Videos;
