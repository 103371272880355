import {dataAttr} from '../../global/utils/data-attr';
import {classList} from '../../global/utils/class-list';

import Factory from '../../global/di/factory';
import Events from '../../global/events/events';
import EventsAndGestures from '../../global/events/events-and-gestures';

import HistoryApi from '../../global/navigation/history-api';
import Navigation from '../../global/navigation/navigation';
import HashScroller from '../../global/navigation/hash-scroller';

import pageInteractionMixin from '../../global/utils/page-interaction-mixin';

import PageComponent from '../../global/page/page-component';
import pageComponentCollectionMixin from '../../global/page/page-component-collection-mixin';
import PageComponentCollection from '../../global/page/page-component-collection';
import PageComponentFactory from '../../global/page/page-component-factory';

import updateStatusMixin from '../../global/utils/update-status-mixin';

import Page from '../../global/page/page';
import PageFactory from '../../global/page/page-factory';
import PageLoader from '../../global/page/page-loader';
import PageManager from '../../global/page/page-manager';
import PageSlot from '../../global/page/page-slot';
import PageSlotFactory from '../../global/page/page-slot-factory';
import PageSlotManager from '../../global/page/page-slot-manager';
import PageTransition from '../../global/page/page-transition';
import PageTransitionFactory from '../../global/page/page-transition-factory';

import Context from '../../global/context/context';
import ContextFactory from '../../global/context/context-factory';
import ContextManager from '../../global/context/context-manager';

import Video from '../../global/media/video';
import Pictures from '../../global/media/pictures';
import Videos from '../../global/media/videos';
import VideoFactory from '../../global/media/video-factory';

import App from '../../global/app/app';

import LoaderAnimation from '../../global/animation/loader-animation';

import FontChecker from '../../global/preload/font-checker';
import StyleChecker from '../../global/preload/style-checker';


import NdhApp from '../app/ndh-app';
import NdhLoaderAnimation from '../animation/ndh-loader-animation';
import DefaultPage from '../page/default-page';

import DefaultPageTransition from '../page/default-page-transition';

import DefaultContext from '../context/default-context';

import Accordion from '../animation/accordion';
import NewsPage from '../page/news-page';
// import WithNewsletterPage from '../page/with-newsletter-page';

import ExhibitionPage from '../page/exhibition-page';
import ExhibitionsPage from '../page/exhibitions-page';
import ArtistPage from '../page/artist-page';

import MainMenu from '../menu/main-menu';
import ExclusionMenu from '../menu/exclusion-menu';
import ColorsChanger from '../menu/colors-changer';
import ContentShifter from '../menu/content-shifter';

//import Newsletter from '../page/newsletter';
// import NewsletterArtLogic from '../page/newsletter-art-logic';
import ReadMore from '../page/read-more';
import SearchExhibitions from '../page/search-exhibitions';
import ArtistVideo from '../page/artist-video';

import NavigationTracker from '../../global/navigation/navigation-tracker';
import GaGtagNavigationTracker from '../../global/navigation/ga-gtag-navigation-tracker';


import Slideshow from '../../global/slideshow/slideshow';
import Slide from '../../global/slideshow/slide';
import ImageSlide from '../../global/slideshow/image-slide';
import TextSlide from '../../global/slideshow/text-slide';
// import VideoSlide from '../../global/slideshow/video-slide';
import SlideFactory from '../../global/slideshow/slide-factory';
import SlideAnimator from '../../global/animation/slide-animator';
// import ProgressAnimator from '../../global/animation/progress-animator';
// import CircleProgressAnimator from '../../global/animation/circle-progress-animator';
import ProgressAnimatorFactory from '../../global/animation/progress-animator-factory';
import BasicSlideshow from '../slideshow/basic-slideshow';


const config = (di) => {
    const prefix = 'ndh-';
    const classPrefix = 'ndh-js-';

    classList.prefix = classPrefix;
    dataAttr.prefix = prefix;

    const baseUrl = dataAttr(document.body).get('baseUrl');

    // generic config

    di
        .setType({type: Factory, params: {container: di}})

        .setType({type: Events})

        .setType({type: EventsAndGestures, parent: Events})


        .setType({
            type: HistoryApi, params: {
                events: di.lazyGet('events'),
                hua: '420'
            }
        })

        .setType({
            type: Navigation, params: {
                events: di.lazyGet('events'),
                history: di.lazyGet('history'),
                baseUrl: baseUrl
            }
        })

        .setType({
            type: HashScroller, mixins: [pageInteractionMixin], params: {
                events: di.lazyGet('events')
            }
        })

        .setMixin({
            mixin: pageInteractionMixin, setters: {
                setEvents: di.lazyGet('events'),
                setPrefix: prefix,
                setClassPrefix: classPrefix,
                setClassList: classList,
                setDataAttr: dataAttr
            }
        })

        .setType({type: Context, name: 'Context', mixins: [pageInteractionMixin, pageComponentCollectionMixin]})

        .setType({type: ContextFactory, parent: Factory})

        .setType({
            type: ContextManager, mixins: [pageInteractionMixin], setters: {
                setContextFactory: di.lazyGet('contextFactory')
            }
        })

        .setType({
            type: PageComponent, mixins: [pageInteractionMixin], setters: {
                setContextManager: di.lazyGet('contextManager')
            }
        })

        .setType({type: PageComponentFactory, parent: Factory})

        .setMixin({
            mixin: pageComponentCollectionMixin, setters: {
                setComponentFactory: di.lazyGet('componentFactory')
            }
        })

        .setMixin({mixin: updateStatusMixin})

        .setType({type: PageComponentCollection, parent: PageComponent, mixins: [pageComponentCollectionMixin]})

        .setType({
            type: Page, name: 'Page', parent: PageComponentCollection, setters: {
                setPageSlotManager: di.lazyGet('pageSlotManager')
            }
        })

        .setType({type: PageFactory, parent: Factory})

        .setType({type: PageSlot, name: 'PageSlot'})

        .setType({type: PageSlotFactory, parent: Factory})

        .setType({
            type: PageSlotManager, params: {
                pageSlotFactory: di.lazyGet('pageSlotFactory')
            }
        })

        .setType({
            type: PageLoader, mixins: [pageInteractionMixin], setters: {
                setPageFactory: di.lazyGet('pageFactory')
            }
        })

        .setType({
            type: PageManager, params: {
                events: di.lazyGet('events'),
                navigation: di.lazyGet('navigation')
            }, setters: {
                setPageLoader: di.lazyGet('pageLoader'),
                setPageTransitionFactory: di.lazyGet('pageTransitionFactory')
            }
        })

        .setType({
            type: PageTransition, name: 'PageTransition', mixins: [pageInteractionMixin], setters: {
                setHistory: di.lazyGet('history'),
                setPageLoader: di.lazyGet('pageLoader'),
                setContextManager: di.lazyGet('contextManager')
            }
        })

        .setType({type: PageTransitionFactory, parent: Factory})

        .setType({
            type: App, parent: PageComponentCollection, setters: {
                setFontChecker: di.lazyNew(FontChecker),
                setStyleChecker: di.lazyNew(StyleChecker),
                setNavigation: di.lazyGet('navigation'),
                setPageManager: di.lazyGet('pageManager')
            }
        })

        .setType({type: LoaderAnimation, parent: PageComponent})

        .setType({type: FontChecker})
        .setType({type: StyleChecker})

        .setType({
            type: Video, name: 'Video', mixins: [updateStatusMixin], params: {
                events: di.lazyGet('events'),
                prefix: prefix
            }
        })

        .setType({type: VideoFactory, parent: Factory})

        .setType({
            type: Videos, name: 'Videos', parent: PageComponent, setters: {
                setVideoFactory: di.lazyGet('videoFactory')
            }
        })

        .setType({type: Pictures, name: 'Pictures', parent: PageComponent})

        .setType({type: SlideAnimator, mixins: [pageInteractionMixin]})

    ;



    // project specific types:

    di
        .setValue('mediaQueries', {
            lt_small: '(max-width: 768px)',
            gt_small: '(min-width: 768px)',
            lt_medium: '(max-width: 1025px)',
            gt_medium: '(min-width: 1025px)'
        })

        //NOTE: in seconds
        .setValue('animationDuration', 0.1)
        .setValue('animationDuration2', 0.2)
        .setValue('animationDuration4', 0.4)

        .setType({
            type: NdhApp, parent: App, params: {
                requiredStyles: ['default'],
                requiredFonts: [
                    {
                        name: 'Futura LT W02 Medium'
                    }/*,
					{
						name: 'Futura Outline'/ *,
						options: {weight: 'bold'}* /
					}*/
                ]
            }, setters: {
                setPageSlotManager: di.lazyGet('pageSlotManager'),
                setHashScroller: di.lazyNew(HashScroller),
                setLoaderAnimation: di.lazyGet('loaderAnimation'),
                injectNavigationTracker: di.lazyNew('GaGtagNavigationTracker')
            }
        })

        .setType({
            type: NdhLoaderAnimation, name: 'LoaderAnimation', parent: LoaderAnimation, params: {
                selector: dataAttr().getSelector('loader')
            }
        })

        .setType({
            type: DefaultPage, name: 'DefaultPage', parent: Page, params: {
                pageSlot: 'default'
            }
        })


        .setType({
            type: DefaultPageTransition, name: 'DefaultPageTransition', parent: PageTransition, setters: {
                setLoaderAnimation: di.lazyGet('loaderAnimation')
            }
        })

        .setType({
            type: DefaultContext, name: 'DefaultContext', parent: Context, params: {
                name: 'default',
                selector: dataAttr().getSelector('context', 'default')
            }
        })

        .setType({
            type: Accordion, name: 'Accordion', parent: PageComponent, params: {
                selector: dataAttr().getSelector('accordion')
            }
        })

        .setType({
            type: NewsPage, name: 'NewsPage', parent: DefaultPage, params: {
                pageSlot: 'default'
            }
        })
        /*
        .setType({type: WithNewsletterPage, name: 'WithNewsletterPage', parent: DefaultPage, params: {
                pageSlot: 'default'
            }
        })
        */
        .setType({
            type: ExhibitionPage, name: 'ExhibitionPage', parent: DefaultPage, params: {
                pageSlot: 'default'
            }
        })

        .setType({
            type: ExhibitionsPage, name: 'ExhibitionsPage', parent: DefaultPage, params: {
                pageSlot: 'default'
            }
        })

        .setType({
            type: ArtistPage, name: 'ArtistPage', parent: DefaultPage, params: {
                pageSlot: 'default'
            }
        })

        .setType({
            type: MainMenu, name: 'MainMenu', parent: PageComponent, params: {
                selector: dataAttr().getSelector('menu'),
                animationDuration: di.lazyValue('animationDuration2'),
                context: 'default'
            }
        })

        .setType({
            type: ExclusionMenu, name: 'ExclusionMenu', parent: PageComponent, params: {
                selector: dataAttr().getSelector('menu-second'),
                context: 'default'
            }
        })

        .setType({
            type: ColorsChanger, name: 'ColorsChanger', parent: PageComponent, params: {
                selector: dataAttr().getSelector('menu'),
                context: 'default'
            }
        })

        .setType({
            type: ContentShifter, name: 'ContentShifter', parent: PageComponent, params: {
                selector: dataAttr().getSelector('menu-second'),
                context: 'default'
            }
        })
        /*
                .setType({type: NewsletterArtLogic, name: 'NewsletterArtLogic', parent: PageComponent, params: {
                    selector: dataAttr().getSelector('newsletter-iframe'),
                    animationDuration: di.lazyValue('animationDuration2'),
                    context: 'default',
                    baseUrl: baseUrl
                }})
        */
        .setType({
            type: ReadMore, name: 'ReadMore', parent: PageComponent, params: {
                animationDuration: di.lazyValue('animationDuration4'),
                context: 'default'
            }
        })

        .setType({
            type: SearchExhibitions, name: 'SearchExhibitions', parent: PageComponent, params: {
                animationDuration: di.lazyValue('animationDuration'),
                context: 'default'
            }
        })

        .setType({
            type: ArtistVideo, name: 'ArtistVideo', parent: PageComponent, params: {
                selector: dataAttr().getSelector('video'),
                animationDuration: di.lazyValue('animationDuration'),
                context: 'default'
            }
        })

        .setType({
            type: NavigationTracker, name: 'NavigationTracker', params: {
                events: di.lazyGet('events')
            }
        })

        .setType({
            type: GaGtagNavigationTracker, name: 'GaGtagNavigationTracker', parent: 'NavigationTracker', params: {
                events: di.lazyGet('events')
            }
        })

        .setType({type: ProgressAnimatorFactory, parent: Factory})
        .setType({type: Slide, mixins: [pageInteractionMixin]})
        .setType({type: ImageSlide, parent: Slide, name: 'ImageSlide'})
        .setType({type: TextSlide, parent: Slide, name: 'TextSlide'})
        // .setType({type: VideoSlide, parent: Slide, name: 'VideoSlide'})
        .setType({type: SlideFactory, parent: Factory})
        .setType({type: SlideAnimator, mixins: [pageInteractionMixin]})
        .setType({
            type: Slideshow,
            parent: PageComponent,
            name: 'Slideshow',
            mixins: [updateStatusMixin],
            params: {},
            setters: {
                setSlideFactory: di.lazyGet('slideFactory'),
                setProgressAnimatorFactory: di.lazyGet('progressAnimatorFactory'),
            }
        })
        .setType(
            {
                type: BasicSlideshow, parent: Slideshow, name: 'BasicSlideshow', params: {
                    selector: dataAttr().getSelector('slideshow'),
                    slideSelector: dataAttr().getSelector('slide')
                }, setters: {
                    setSlideAnimator: di.lazyNew(SlideAnimator)
                }
            })
    ;



    // setting services

    di
        .set('app', di.lazyNew(NdhApp))
        .set('history', di.lazyNew(HistoryApi))
        .set('navigation', di.lazyNew(Navigation))
        .set('navigation', di.lazyNew(Navigation))
        .set('events', di.lazyNew(EventsAndGestures))
        .set('componentFactory', di.lazyNew(PageComponentFactory))
        .set('pageFactory', di.lazyNew(PageFactory))
        .set('pageLoader', di.lazyNew(PageLoader))
        .set('pageManager', di.lazyNew(PageManager))
        .set('pageSlotFactory', di.lazyNew(PageSlotFactory))
        .set('pageSlotManager', di.lazyNew(PageSlotManager))
        .set('contextFactory', di.lazyNew(ContextFactory))
        .set('contextManager', di.lazyNew(ContextManager))
        .set('loaderAnimation', di.lazyNew(NdhLoaderAnimation))
        .set('pageTransitionFactory', di.lazyNew(PageTransitionFactory))
        .set('videoFactory', di.lazyNew(VideoFactory))
        .set('slideFactory', di.lazyNew(SlideFactory))
        .set('progressAnimatorFactory', di.lazyNew(ProgressAnimatorFactory))
    ;
};


export default config;
