import {isString} from '../utils/types';
import pageInteractionMixin from '../utils/page-interaction-mixin';


class PageComponent extends pageInteractionMixin() {

	// selector = true means that is going to inherit the parent element
	constructor({context = null, selector = true, config = {}, status = {}} = {}) {
		super();
		this.context = context;
		this.selector = selector;

		this.config = Object.assign({
			async: false
		}, config || {});

		this.status = Object.assign({
			ready: false,
			active: false
		}, status || {});

		this.element = null;
	}



	setContextManager(contextManager) {
		this.contextManager = contextManager;
		if (this.context !== null) {
			this.context = contextManager.getContext(this.context);
		}
	}


	init(element, done = () => {}) {
		if (!this.status.ready) {
			if (this.element === null) {
				this.setElement(element);
			}
			if (this.context) {
				this.context.addComponent(this);
			}
			this.prepare(this.element, () => {
				this.status.ready = true;
				done();
			});
		} else {
			done();
		}
		return this;
	}


	deinit(done = () => {}) {
		if (this.status.ready) {
			if (this.status.active) {
				this.deactivate();
			}
			if (this.context) {
				this.context.removeComponent(this);
			}
			this.clear(() => {
				this.status.ready = false;
				done();
			});
		} else {
			done();
		}
		return this;
	}


	activate(force = false) {
		if (!this.status.active && (force || !this.context || this.context.isActive())) {
			this.status.active = true;
			this.start();
		}
		return this;
	}


	deactivate() {
		if (this.status.active) {
			this.status.active = false;
			this.stop();
		}
		return this;
	}


	prepare(element, done) {
		done();
	}


	clear(done) {
		done();
	}


	start() {

	}


	stop() {

	}


	setElement(element) {
		if (isString(element)) {
			element = document.querySelector(element);
		}
		this.element = element;
		return this;
	}


	getElement() {
		return this.element;
	}


	getSelector() {
		return this.selector;
	}


	isAsync() {
		return !!this.config.async;
	}


}

export default PageComponent;
