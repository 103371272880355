import {isString} from '../utils/types';
import data from '../utils/data';
import updateStatusMixin from '../utils/update-status-mixin';


class Video extends updateStatusMixin() {
    constructor({events, prefix, element, linkedElements = [], appendVideoInside = true}) {
		super();
		this.events = events;
		this.prefix = prefix;
		this.classPrefix = prefix + 'js-';
        if (isString(element)) {
            element = document.querySelector(element);
        }
        if (!element) {
            throw new Error('Video Element not found');
        }
        this.element = element;
        this.appendVideoInside = appendVideoInside;

        this.linkedElements = linkedElements;
        this.video = null;
        this.poster = null;
        this.currentSrcset = null;
        this.status = {
            ready: false,
            loading: false,
            canplay: false,
            metaready: false,
            playing: false,
            paused: true,
            ended: false,
            loop: false,
            muted: false
        };
		this.wantPlay = false;
        this.config = data(this.element, 'videoConfig');
        this.init();
    }


    init() {
        const video = this.createVideo();
        this.video = video;
        this
            .initSources()
            .initClasses()
            .initPoster()
            .attachToDocument();

        this
            .updateStatus(true)
            .addListeners()
            .initProps()
            .updateStatus('ready', true);
        return this;
    }


    deinit() {
        if (this.status.playing && this.video) {
            this.pause();
        }
        this.removeListeners();
        this.element = null;
        this.video = null;
        this.poster = null;
        this.linkedElements = null;
        this.currentSrcset = null;
        return this;
    }


    initSources() {
        for (const srcset of this.config.sources) {
            if (!('media' in srcset) || (srcset.media === null) || matchMedia(srcset.media).matches) {
                this.currentSrcset = srcset;
                for (const file of srcset.files) {
                    this.video.appendChild(this.createSource(file.src, file.type));
                }
                break;
            }
        }
        return this;
    }


    initClasses() {
        if (this.config.classes) {
            if (isString(this.config.classes)) {
                this.config.classes = this.config.classes.split(' ');
            }
            this.video.classList.add(...this.config.classes);
        }
        return this;
    }


    initPoster() {
        this.poster = this.element.querySelector('[data-media="videoPoster"]');
        // if (this.poster) {
        //     if (!this.status.disabled) {
		// 		const img = this.element.querySelector('img');
		// 		const load = () => {
		// 			if (img.currentSrc && img.currentSrc.length) {
		// 				this.video.poster = img.currentSrc;
		// 			} else {
		// 				setTimeout(load, 20);
		// 			}
		// 		};
        //         // if (!this.poster) {
        //         //     this.poster = this.createFallbackImg(this.config.poster, this.config.alt || '');
		// 		// 	this.video.appendChild(this.poster);
        //         // }
        //     }
        // }
        return this;
    }


    attachToDocument() {
        this.element.wrapper = this;
        this.video.wrapper = this;
        this.linkedElements.unshift(this.video);
        if (this.appendVideoInside) {
            this.element.appendChild(this.video);
            this.linkedElements.unshift(this.element);
        } else {
            this.element.parentNode.insertBefore(this.element, this.video);
            this.element.parentNode.removeChild(this.element);
            this.element = this.video;
        }
        return this;
    }


    initProps() {
        for (const name in this.config.props) {
            if (this.config.props.hasOwnProperty(name)) {
                this.video[name] = this.config.props[name];
            }
        }
        for (const name in this.config.attributes) {
            if (this.config.attributes.hasOwnProperty(name)) {
                this.video.setAttribute(name, this.config.attributes[name]);
            }
        }
        if (this.config.default.muted) {
            this.video.muted = true;
        }
        if (this.config.default.paused) {
            this.video.pause();
        } else {
            this.video.autostart = this.video.autoplay = true;
        }
        return this;
    }


    addListeners() {
        this.listeners = [];
        this.listeners.push(this.events.on(this.video, 'canplaythrough', this.onCanPlayThrough.bind(this)));
        this.listeners.push(this.events.on(this.video, 'loadeddata', this.onLoadedData.bind(this)));
        this.listeners.push(this.events.on(this.video, 'loadedmetadata', this.onLoadedMeta.bind(this)));
        this.listeners.push(this.events.on(this.video, 'playing', this.onPlaying.bind(this)));
        this.listeners.push(this.events.on(this.video, 'pause', this.onPause.bind(this)));
        this.listeners.push(this.events.on(this.video, 'ended', this.onEnded.bind(this)));
        return this;
    }


    removeListeners() {
        for (const listener of this.listeners) {
            listener.destroy();
        }
        return this;
    }


    onCanPlayThrough(event) {
        if (!this.status.canplay) {
            if (!this.status.metaready) {
                this.onLoadedMeta(event);
            }
            this.updateStatus('canplay', true);
            this.events.trigger(this.element, 'video:canplay', {
                wrapper: this,
                video: this.video
            });
			if (this.wantPlay && !this.status.playing) {
				this.play();
			}
        }
    }


    onLoadedData(event) {
        // show the first frame when is ready
        this.seek(0.001);
        this.events.trigger(this.element, 'video:loadeddata', {
            wrapper: this,
            video: this.video
        });
    }


    onLoadedMeta(event) {
        this.updateStatus('metaready', true);
        this.events.trigger(this.element, 'video:loadedmetadata', {
            wrapper: this,
            video: this.video
        });
    }


    onPlaying(event) {
        this.updateStatus({playing: true, paused: false, ended: false});
        this.startLoadingCheck();
        this.events.trigger(this.element, 'video:playing', {
            wrapper: this,
            video: this.video
        });
		if (this.poster) {
			setTimeout(() => {
				if (this.poster && this.poster.parentNode) {
					//this.poster.parentNode.removeChild(this.poster);
					this.poster = null;
				}
			}, 10);
		}
    }


    onPause(event) {
        this.updateStatus({playing: false, paused: true});
        this.stopLoadingCheck();
        this.events.trigger(this.element, 'video:pause', {
            wrapper: this,
            video: this.video
        });
    }


    onEnded(event) {
        this.stopLoadingCheck();
        this.updateStatus({playing: false, paused: true, ended: true});
        this.events.trigger(this.element, 'video:ended', {
            wrapper: this,
            video: this.video
        });
    }


    startLoadingCheck() {

    }


    stopLoadingCheck() {

    }


    createVideo() {
        return document.createElement('video');
    }


    createSource(src, type) {
        const source = document.createElement('source');
        source.setAttribute('src', src);
        source.setAttribute('type', type);
        return source;
    }


    createFallbackImg(src, alt = '') {
        const img = document.createElement('img');
        data(img, 'media', 'videoPoster');
        img.setAttribute('src', src);
        img.setAttribute('alt', alt);
        return img;
    }


    play() {
        if (this.video && this.video.play) {
			this.wantPlay = true;
            const videoPromise = this.video.play();
            if (videoPromise) {
                videoPromise.catch(() => {});
            }
        }
        return this;
    }


    pause() {
        if (this.video && this.video.pause) {
			this.wantPlay = false;
            this.video.pause();
        }
        return this;
    }


    restart() {
        return this.seek(0).play().catch(() => {});
    }


    reverse() {
        this.video.playbackRate = -this.video.playbackRate;
        return this;
    }


    speed(value = null) {
        if (value !== null) {
            this.video.playbackRate = value;
            return this;
        }
        return this.video.playbackRate;
    }


    seek(value) {
        this.video.currentTime = value;
        return this;
    }


    duration() {
        return this.video.duration;
    }


    time(value = null) {
        if (value !== null) {
            return this.seek(value);
        }
        return Math.round(this.video.currentTime * 1000) / 1000;
    }


    progress(value = null) {
        if (value !== null) {
            value = Math.max(0, Math.min(1, value));
            return this.seek(this.duration() * value);
        }
        return Math.round(this.time() / this.duration() * 1000) / 1000;
    }


    volume(value = null) {
        if (value !== null) {
            this.video.volume = Math.max(0, Math.min(1, value));
            return this;
        }
        return this.video.volume;
    }


    muted(value = null) {
        if (value !== null) {
            this.video.muted = !!value;
            return this;
        }
        return this.video.muted;
    }

}


export default Video;
