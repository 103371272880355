// require modernizr to set the csstransitions or no-csstransitions class on <html> element

import {js} from 'prefix-property';

let transitionEvent = null;

let transitionSupport = null;


function transitionSupported() {
    if (transitionSupport === null) {
        transitionSupport = document.documentElement.classList.contains('csstransitions');
    }
    return transitionSupport;
}

function toMs(value) {
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
        return 0;
    }
    return (value.indexOf('ms') === -1 && value.indexOf('s') > -1 ? numericValue * 1000 : numericValue);
}


function getTransitionDuration(element) {
    if (!transitionSupported()) {
        return 0;
    }
    const style = getComputedStyle(element);
    let duration = 0;
    let delay = 0;
    const durations = style[js('transition-duration')].split(/\s*,\s/);
    const delays = style[js('transition-delay')].split(/\s*,\s/);
    for (const value of durations) {
        duration = Math.max(duration, toMs(value));
    }
    for (const value of delays) {
        delay = Math.max(delay, toMs(value));
    }
    return duration + delay;
}


function getTransitionEvent() {
    if (!transitionEvent) {
        const prefixedTransition = js('transition');
        const values = {
            transition: 'transitionend',
            OTransition: 'otransitionend',
            MozTransition: 'transitionend',
            WebkitTransition: 'webkitTransitionEnd'
        };

        if (prefixedTransition in values) {
            transitionEvent = values[prefixedTransition];
        } else {
            transitionEvent = 'transitionend';
        }
    }
    return transitionEvent;
}


export default function (element, callback, errorMargin = 100) {
    if (!transitionSupported()) {
        setTimeout(callback, 0);
    } else {
        let raised = false;
        const eventName = getTransitionEvent();
        const duration = getTransitionDuration(element) + errorMargin;
        const timeout = setTimeout(() => {
            if (!raised) {
                raised = true;
                callback();
            }
        }, duration);

        const internalCallback = (event) => {
            // in some cases the visibility transitionend triggers before the overall duration
            if (event.target === element && event.propertyName !== 'visibility') {
                element.removeEventListener(eventName, internalCallback, false);
                if (!raised) {
                    raised = true;
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                    callback(event);
                }
            }
        };
        element.addEventListener(eventName, internalCallback, false);
    }
}
