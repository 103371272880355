import Factory from '../di/factory';


class SlideFactory extends Factory {

	constructor({container, prefix = '', suffix = 'Slide'}) {
		super({container: container, prefix: prefix, suffix: suffix});
    }

}

export default SlideFactory;
