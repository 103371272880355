class Lazy {

    constructor(callback, creationCallback = null, settersCallback = null, callbacksCallback = null) {
        this.callback = callback;
        this.creationCallback = creationCallback;
        this.settersCallback = settersCallback;
        this.callbacksCallback = callbacksCallback;
    }

    resolve() {
        return this.callback();
    }


    resolveCreation() {
        if (this.creationCallback) {
            return this.creationCallback();
        } else {
            throw new Error('No creation callback defined');
        }
    }


    resolveSetters(instance) {
        if (this.settersCallback) {
            return this.settersCallback(instance);
        } else {
            throw new Error('No setters callback defined');
        }
    }


    resolveCallbacks(instance) {
        if (this.callbacksCallback) {
            return this.callbacksCallback(instance);
        } else {
            throw new Error('No callbacks callback defined');
        }
    }

}

export default Lazy;
