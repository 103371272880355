class StyleChecker {

    constructor({classPrefix = 'style-', classSuffix = '-loaded'} = {}) {
        this.classPrefix = classPrefix;
        this.classSuffix = classSuffix;
        this.html = document.documentElement || document.querySelector('html');
    }

    checkStyle(name, done) {
        const check = () => {
            if (this.html.classList.contains(this.classPrefix + name + this.classSuffix)) {
                done();
            } else {
                setTimeout(check);
            }
        };
        check();
    }

}


export default StyleChecker;
