import {isString} from './types';
import data from './data';


const updateStatusMixin = (Base = null) => class extends Base {

	updateLinkedElements(type = 'class', statusProperty = 'status', linkedElementsProperty = 'linkedElements') {
		const status = this[statusProperty];
		const linkedElements = isString(this[linkedElementsProperty]) ? document.querySelectorAll(this[linkedElementsProperty]) : this[linkedElementsProperty];
		if (linkedElements && linkedElements.length) {
			for (const element of linkedElements) {
				for (const property in status) {
					if (status.hasOwnProperty(property)) {
						if (type === 'class') {
							element.classList.toggle((this.classPrefix || '') + property, status[property]);
						} else {
							data(element, (this.prefix || '') + property, status[property]);
						}
					}
				}
			}
		}
		return this;
	}


	updateStatus(name, value, type = 'class', statusProperty = 'status', linkedElementsProperty = 'linkedElements') {
		const status = this[statusProperty];

	    let changed = false;
	    if (name === true) {
	        changed = true;
	    } else {
	        let obj;
	        if (isString(name)) {
	            obj = {};
	            obj[name] = value;
	        } else {
	            obj = name;
	        }

	        for (name in obj) {
	            if (obj.hasOwnProperty(name)) {
	                value = obj[name];
	                const singleChanged = !(name in status) || status[name] !== value;
	                changed = changed || singleChanged;
	                status[name] = value;
	            }
	        }
	    }
	    if (changed) {
			this.updateLinkedElements(type, statusProperty, linkedElementsProperty);
	    }
	    return this;
	}


};


export default updateStatusMixin;
