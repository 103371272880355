/*eslint-disable piggyback/no-restricted-global-extend*/
// const ga = window.ga;

class NavigationTracker {

    constructor({events}) {
		this.events = events;
		// console.log(events, hua);
		this.enabled = true;
		this.trackEventEnabled = false;
		this.events.on(document, 'history:pagechange', this.onChange.bind(this));
		this.init();
		this.clickTrackAttribute = 'click-track';
		this.clickTrackSelector = '[data-' + this.clickTrackAttribute + ']';
		this.clickListener = null;
		this.clickTrackEnabled = false;
		if (this.clickTrackEnabled) {
			this.enableClickTrack();
		}
    }


	// to be overriden
	init() {}

	// to be overriden
	doTrack(url, title) {}

	// to be overriden
	doTrackEvent(category, action, name = null, value = null) {}


	track(url, title) {
		if (this.enabled) {
			this.doTrack(url, title);
		} else {
			console.log('page change:', url, title);
		}
		return this;
	}


	trackEvent(category, action, name = null, value = null) {
		if (this.trackEventEnabled) {
			this.doTrackEvent(category, action, name, value);
		} else {
			console.log('tracking event', category, action, name, value);
		}
		return this;
	}


	onChange(event) {
		this.track(event.detail.url, event.detail.title);
	}


	enableClickTrack() {
		if (!this.clickTrackEnabled) {
			this.clickTrackEnabled = true;
			this.clickListener = this.events.on(document, this.clickTrackSelector, 'click', this.onTrackedClick.bind(this), {capture: true, passive: true});
		}
		return this;
	}


	disableClickTrack() {
		if (this.clickTrackEnabled) {
			this.clickTrackEnabled = false;
			this.clickListener.destroy();
		}
		return this;
	}


	onTrackedClick(event, target) {
		target = target.closest(this.clickTrackSelector);
		try {
			const data = JSON.parse(target.getAttribute('data-' + this.clickTrackAttribute));
			const category = data.category;
			const action = data.action;
			const name = 'name' in data ? data.name : null;
			const value = 'value' in data ? data.value : null;
			this.trackEvent(category, action, name, value);
		} catch(e) {
			console.log('unable to track the click on', target);
		}
	}
}


export default NavigationTracker;
