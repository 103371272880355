import {getScrollTop} from 'get-scroll';

import getOffsetTop from '../../global/utils/offset-top';
import Context from '../../global/context/context';


class DefaultContext extends Context {

    constructor({name, selector, outClass = 'covered', homeMapOutClass = 'inactive'}) {
        super({name: name, selector: selector});
        this.prevScrollPos = 0;
        this.outClass = outClass;
        this.homeMapOutClass = homeMapOutClass;
    }


    switchIn(done, otherContext) {
        const element = this.getElement();
        this.classList(element).remove(this.outClass, this.homeMapOutClass);
		element.style.removeProperty('top');
		window.scrollTo(0, this.prevScrollPos);
        super.switchIn(done, otherContext);
    }


    switchOut(done, otherContext) {
        const element = this.getElement();
        const currentOffset = getOffsetTop(element);
        const scrollPos = getScrollTop();
        this.prevScrollPos = scrollPos;
        this.classList(element).add((otherContext.getName() === 'homeMap' ? this.homeMapOutClass : this.outClass));
        element.style.top = (currentOffset - scrollPos) + 'px';
        window.scrollTo(0, 0);
        super.switchOut(done, otherContext);
    }

}


export default DefaultContext;
