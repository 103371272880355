import Slide from './Slide';


class TextSlide extends Slide {

    constructor({slideshow, element, index, type = 'text', subtype, duration}) {
		super({slideshow: slideshow, element: element, index: index, type: type, subtype: subtype, duration: duration});
    }


    load() {
		super.load();
        return this;
    }

}

export default TextSlide;
