import PageComponent from '../page/page-component';


class Pictures extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {}, batchSize = 20} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.batchSize = batchSize;
	}


    prepare(element, done) {
		const pictures = element.querySelectorAll('[data-async-picture]');
		let i = 0;
		const amount = pictures.length;
		const batch = () => {
			let j = 0;
			while (i < amount && j < this.batchSize) {
				const picture = pictures[i];
				for (const node of picture.querySelectorAll('[data-srcset]')) {
					node.setAttribute('srcset', node.getAttribute('data-srcset'));
					node.removeAttribute('data-srcset');
				}
				i++;
				j++;
			}
			if (i < amount) {
				requestAnimationFrame(batch);
			}
		};

		if (amount) {
			batch();
		}
        done();
    }

}

export default Pictures;
