import httpRequest from 'superagent';
import {isObject} from '../../global/utils/types';
import pageInteractionMixin from '../utils/page-interaction-mixin';


class PageLoader extends pageInteractionMixin() {

    constructor({attribute = 'page', timeout = 10000, format = 'json', query = {json: 1}} = {}) {
		super();
		this.attribute = attribute;
		this.timeout = timeout;
		this.format = format;
		this.query = query;
    }


	setPageFactory(pageFactory) {
		this.pageFactory = pageFactory;
	}


	load(request, callback) {
	    this.requestPage(request, (response) => {
			const fragment = isObject(response) && 'content' in response ? response.content : response;
			const pages = this.fetchPages(fragment);
			callback(pages, response);
		});
	}


	requestPage(request, callback) {
		const req = httpRequest
            .get(request.url)
            .timeout(this.timeout)
            .set('X-Requested-With', 'XMLHttpRequest');
		if (this.format === 'json') {
			req.set('Accept', 'application/json');
		}
        req.query(this.query);

        req.end((error, response) => {
            if (error || !response.ok) {
                if (!error.response) {
                    error.response = response;
					throw error;
                } else {
					if (error.status === 404) {
						callback(error.response.body);
					} else {
						throw error;
					}
				}
            } else {
                callback(response.body);
            }
        });
	}


	fetchPages(fragment) {
		let element;
		if (fragment !== document.body && (!(fragment instanceof Node) || !document.body.contains(fragment))) {
			element = document.createElement('div');
	        element.innerHTML = fragment;
		} else {
			element = fragment;
		}
		const pageElements = element.querySelectorAll(this.dataSelector(this.attribute));
		const pages = [];
		for (const pageElement of pageElements) {
			const pageName = this.dataAttr(pageElement).get(this.attribute);
			const page = this.pageFactory.newInstance(pageName);
			page.setElement(pageElement);
			pages.push(page);
		}
        return pages;
    }

}


export default PageLoader;
