import 'intersection-observer';
import svg4everybody from 'svg4everybody';
import picturefill from 'picturefill';
import {gsap} from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';
import App from '../../global/app/app';


class NdhApp extends App {

	setPageSlotManager(pageSlotManager) {
		this.pageSlotManager = pageSlotManager;
	}

	setHashScroller(hashScroller) {
		this.hashScroller = hashScroller;
	}

	setLoaderAnimation(loaderAnimation) {
		this.loaderAnimation = loaderAnimation;
	}

	injectNavigationTracker(tracker) {
		this.tracker = tracker;
		this.tracker.init();
	}

	prepare(element, done) {
		svg4everybody();
		picturefill();
		gsap.registerPlugin(ScrollToPlugin);

		this.loaderAnimation.init(this.element.querySelector(this.loaderAnimation.getSelector()));
		this.loaderAnimation.activate();

		this.pageSlotManager.addNewPageSlot('', {name: 'default', selector: this.dataSelector('pageSlot', 'default')});

		this.contextManager.addNewContext('default');
		this.contextManager.setDefaultContext('default', false);

		this.pageManager.appendNewPageTransition('default');

		this.addNewComponent('MainMenu');
		this.addNewComponent('ColorsChanger');

		// const newsletter = this.addNewComponent('NewsletterArtLogic');
		// newsletter.setElement(document.querySelector(newsletter.getSelector()));

		done();
	}


	clear(done) {
		done();
	}


	start() {
		this.pageManager.initCurrentPages(this.element, () => {
			this.contextManager.getContext('default').activate();
			this.classList(document.querySelector('body')).add('loaded');

			this.loaderAnimation.pause();

			const hash = location.hash;
			if (hash.length) {
				this.events.trigger(document.body, 'history:hashchange', {hash: hash.substr(1)});
			}
		});
	}


}

export default NdhApp;
