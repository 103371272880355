import asq from 'asynquence';


class PageManager {

	constructor({events, navigation}) {
		this.events = events;
		this.navigation = navigation;

		this.pageTransitions = [];

		this.pendingRequest = null;
		this.busy = false;
		if (this.navigation.isHistoryApiSupported()) {
			this.events.on(document, 'history:navigate', this.onNavigate.bind(this));
		}
	}


	setPageLoader(pageLoader) {
		this.pageLoader = pageLoader;
	}

	setPageTransitionFactory(factory) {
		this.pageTransitionFactory = factory;
	}


	onNavigate(event) {
		if (this.busy) {
			for (const transition of this.pageTransitions) {
				if (transition.match(event)) {
					event.preventDefault();
			        if (event.detail.navigationType === 'link' && event.detail.originalEvent) {
			                event.detail.originalEvent.preventDefault();
		            }
					this.pendingRequest = event;
					break;
				}
			}
		} else {
			this.processNavigationEvent(event);
		}
	}


	processNavigationEvent(event) {
		this.busy = true;
		const onEnd = () => {
			if (this.pendingRequest) {
				setTimeout(() => {
					const pendingRequest = this.pendingRequest;
					this.pendingRequest = null;
					this.processNavigationEvent(pendingRequest);
				}, 0);
			}
			this.busy = false;
		};
		let matched = false;
		for (const transition of this.pageTransitions) {
			if (transition.match(event)) {
				matched = true;
				event.preventDefault();
		        if (event.detail.navigationType === 'link' && event.detail.originalEvent) {
		                event.detail.originalEvent.preventDefault();
	            }
				transition
					.init(event)
					.start(onEnd);
				break;
			}
		}
		if (!matched) {
			onEnd();
		}
	}


	initCurrentPages(element, done) {
		const pages = this.pageLoader.fetchPages(element);
		const sequence = asq();
		for (const page of pages) {
			const pageElement = page.getElement();
			sequence.then((stepDone) => page.init(pageElement, () => {
				page.setAsCurrent();
				stepDone();
			}));
		}
		sequence.then(done);
		return this;
	}


	appendPageTransition(transition) {
		this.pageTransitions.push(transition);
		return this;
	}


	prependPageTransition(transition) {
		this.pageTransitions.unshift(transition);
		return this;
	}


	appendNewPageTransition(name, params = {}) {
		const transition = this.pageTransitionFactory.newInstance(name, params);
		this.appendPageTransition(transition);
		return transition;
	}


	prependNewPageTransition(name, params = {}) {
		const transition = this.pageTransitionFactory.newInstance(name, params);
		this.prependPageTransition(transition);
		return transition;
	}

}


export default PageManager;
