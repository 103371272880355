import onTransitionEnd from '../../global/utils/transition-end';
import LoaderAnimation from '../../global/animation/loader-animation';


class NdhLoaderAnimation extends LoaderAnimation {

    play(done = () => {}, options = {}) {
        this.classList(document.body).add('loading');
        onTransitionEnd(document.body, done);
		done();
    }


    pause(done = () => {}, options = {}) {
        this.classList(document.body).remove('loading');
        onTransitionEnd(document.body, done);
		done();
    }

}

export default NdhLoaderAnimation;
