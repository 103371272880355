import {camelize, decamelize} from './string';
import {isObject, isArray} from './types';


class DataAttr {

	constructor(element, prefix = '') {
		this.element = element;
		this.prefix = prefix;
	}


	setElement(element) {
		this.element = element;
		return this;
	}


	setPrefix(prefix) {
		this.prefix = prefix;
		return this;
	}


	getPrefix(prefix) {
		return this.prefix;
	}


	static encodeValue(val) {
	    if (isObject(val) || isArray(val)) return JSON.stringify(val);
	    if (val === true) return 'true';
	    if (val === false) return 'false';
	    if (val === null) return 'null';
	    return val + '';
	}


	// inspired by jquery data attributes parsing
	static decodeValue(val) {
	    if (typeof val === 'string') {
	        if (val === 'true') return true;
	        if (val === 'false') return false;
	        if (val === 'null') return null;

	        try {
	            // Only convert to a number if it doesn't change the string
	            if (+val + '' === val) return +val;
	            if (/^(?:\{[\w\W]*\}|\[[\w\W]*\])$/.test(val)) return JSON.parse(val);
	            return val;
	        } catch (e) {
	            return val;
	        }
	    }
	    return undefined;
	}


	getAttributeName(name) {
		return 'data-' + this.prefix + decamelize(name);
	}


	getSelector(name, value = undefined) {
		let selector = '[' + this.getAttributeName(name);
		if (value !== undefined) {
			selector += '="' + DataAttr.encodeValue(value) + '"';
		}
		return selector + ']';
	}


	removePrefix(name) {
		if (this.prefix.length && name.substr(0, this.prefix.length) === this.prefix) {
			name = name.substr(this.prefix.length);
		}
		return name;
	}


	set(name, value) {
		this.element.setAttribute(this.getAttributeName(name), DataAttr.encodeValue(value));
		return this;
	}


	get(name) {
		return DataAttr.decodeValue(this.element.getAttribute(this.getAttributeName(name)));
	}


	has(name) {
		return this.element.hasAttribute(this.getAttributeName(name));
	}


	getAll() {
		const values = {};
        for (let i = 0, end = this.element.attributes.length; i < end; i++) {
			const attr = this.element.attributes[i];
            if (attr.name.indexOf('data-') === 0) {
                values[camelize(this.removePrefix(attr.name.substr(5)))] = DataAttr.decodeValue(attr.value);
            }
        }
		return values;
	}


	remove(name) {
		this.element.removeAttribute(this.getAttributeName(name));
		return this;
	}
}


const dataAttr = (element = null, prefix = false) => new DataAttr(element, prefix !== false ? prefix : dataAttr.prefix);
dataAttr.prefix = '';


export {DataAttr, dataAttr};
