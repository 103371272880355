import Lazy from './lazy';

class Container {

    constructor() {
        this.types = new Map();
		this.mixins = new Map();
        this.services = {};
        this.values = {};
        this.names = {};

        this.locked = new Map();
    }


    setType({type, parent = null, name = null, params = null, mixins = [], setters = {}, callbacks = []}) {
        this.types.set(type, {
            parent: parent,
            params: params,
			mixins: mixins,
            setters: setters,
            callbacks: callbacks
        });
        if (name !== null) {
            this.names[name] = type;
        }
        return this;
    }


	setMixin({mixin, setters = {}, callbacks = []}) {
        this.mixins.set(mixin, {
            setters: setters,
            callbacks: callbacks
        });
        return this;
    }


    set(name, service) {
        this.services[name] = service;
        return this;
    }


    setValue(name, value) {
        this.values[name] = value;
        return this;
    }


    has(name) {
        return (name in this.services);
    }


    getFactory(map = {}) {
        return (type, params) => {
            if (type in map) {
                type = map[type];
            }
            return this.newInstance(type, params);
        };
    }


    getServiceProvider(allowedServices = []) {
        return (name) => {
            if (allowedServices.length && allowedServices.indexOf(name) === -1) {
                throw new Error('Service ' + name + ' not available in this context');
            }
            return this.get(name);
        };
    }


    get(name) {
        if (!(name in this.services)) {
            throw new Error('Service ' + name + ' not defined');
        }
        if (this.services[name] instanceof Lazy) {
            const lazy = this.services[name];
            this.services[name] = lazy.resolveCreation();
            lazy.resolveSetters(this.services[name]);
            lazy.resolveCallbacks(this.services[name]);
        }
        return this.services[name];
    }


    getValue(name) {
        if (!(name in this.values)) {
            throw new Error('Value ' + name + ' not defined');
        }
        if (this.values[name] instanceof Lazy) {
            this.values[name] = this.values[name].resolve();
        }
        return this.values[name];
    }


    lazyNew(type, params = null) {
        return new Lazy(
            () => (this.newInstance(type, params)),
            () => (this.newInstanceCreation(type, params)),
            (instance) => (this.newInstanceSetters(instance)),
            (instance) => (this.newInstanceCallbacks(instance))
        );
    }


    lazyGet(name) {
        return new Lazy(() => (this.get(name)));
    }


    lazyValue(name) {
        return new Lazy(() => (this.getValue(name)));
    }


    newInstance(type, params = null) {
        type = this.getType(type);
        const instance = this.newInstanceCreation(type, params);
        this.newInstanceSetters(instance);
        this.newInstanceCallbacks(instance);
        return instance;
    }


    newInstanceCreation(type, params = null) {
        if (this.locked.has(type)) {
            throw new Error('Loop detected in creating an object ' + type.name);
        }
        this.locked.set(type, true);
        params = this.resolveParams(type, params);
        const instance = this.create(type, params);
        this.locked.delete(type);
        return instance;
    }


    newInstanceSetters(instance) {
        const type = Object.getPrototypeOf(instance).constructor;
        const setters = this.resolveSetters(type);
        for (const name in setters) {
            if (setters.hasOwnProperty(name)) {
                if (instance[name]) {
                    instance[name](setters[name]);
                } else {
                    throw new Error('method ' + name + ' not found in ' + type.name);
                }
            }
        }
        return instance;
    }


    newInstanceCallbacks(instance) {
        const type = Object.getPrototypeOf(instance).constructor;
        const callbacks = this.resolveCallbacks(type);
        for (const callback of callbacks) {
            callback(instance, this);
        }
        return instance;
    }


    resolveParams(type, params = null) {
        params = this.fetchParams(type, params);
        if (Array.isArray(params)) {
            for (let i = 0, end = params.length; i < end; i++) {
                if (params[i] instanceof Lazy) {
                    params[i] = params[i].resolve();
                }
            }
        } else {
            for (const name in params) {
                if (params.hasOwnProperty(name) && params[name] instanceof Lazy) {
                    params[name] = params[name].resolve();
                }
            }
        }
        return params;
    }


    resolveSetters(type) {
		let entry = null;
		let isClass;
		if (this.types.has(type)) {
			entry = this.types.get(type);
			isClass = true;
		} else if (this.mixins.has(type)) {
			entry = this.mixins.get(type);
			isClass = false;
		} else {
			return {};
		}
		let setters;
		if (isClass) {
			setters = this.resolveSetters(entry.parent);
			for (const mixin of entry.mixins) {
				setters = Object.assign(setters, this.resolveSetters(mixin));
			}
		} else {
			setters = {};
		}
        setters = Object.assign(setters, entry.setters);
        for (const name in setters) {
            if (setters.hasOwnProperty(name)) {
                if (setters[name] instanceof Lazy) {
                    setters[name] = setters[name].resolve();
                }
            }
        }
        return setters;
    }


    resolveCallbacks(type) {
		let entry = null;
		let isClass;
		if (this.types.has(type)) {
			entry = this.types.get(type);
			isClass = true;
		} else if (this.mixins.has(type)) {
			entry = this.mixins.get(type);
			isClass = false;
		} else {
			return [];
		}
		let callbacks;
		if (isClass) {
			callbacks = this.resolveCallbacks(entry.parent);
			for (const mixin of entry.mixins) {
				callbacks = callbacks.concat(this.resolveCallbacks(mixin));
			}
		} else {
			callbacks = [];
		}
        callbacks = callbacks.concat(callbacks, entry.callbacks);
        return callbacks;
    }


    fetchParams(type, params = null) {
        let finalParams = null;
        const parentType = this.types.get(type).parent;
        if (!Array.isArray(params)) {
            const paramSets = [
                parentType && this.types.has(parentType) ? this.fetchParams(parentType) : null,
                this.types.get(type).params,
                params
            ];
            for (let paramSet of paramSets) {
                if (Array.isArray(paramSet)) {
                    paramSet = paramSet.slice(0);
                } else if (paramSet !== null) {
                    paramSet = Object.assign({}, paramSet);
                }
                if (finalParams === null) {
                    finalParams = paramSet;
                } else if (paramSet !== null) {
                    if (Array.isArray(finalParams)) {
                        if (!Array.isArray(paramSet)) {
                            throw new Error('Unable to mix named and position params in ' + type.name);
                        }
                        finalParams = finalParams.concat(paramSet);
                    } else {
                        finalParams = Object.assign(finalParams, paramSet);
                    }
                }
            }
        } else {
            finalParams = params;
        }

        return finalParams;
    }


    create(constructor, args) {
        if (args === null) {
            return new constructor();
        }
        if (Array.isArray(args)) {
            return new constructor(...args);
        }
        return new constructor(args);
    }


    getType(type) {
        if (this.types.has(type)) {
            return type;
        }
        if (type in this.names) {
            return this.names[type];
        }
        throw new Error('Type ' + type + ' not registered');
    }

}


export default Container;
