import Slideshow from '../../global/slideshow/slideshow';


class BasicSlideshow extends Slideshow {
    constructor({
                    slideSelector,
                    context = null,
                    selector = true,
                    config = {},
                    status = {loading: true, available: false, busy: false, paused: false},
                    disabledClass = 'disabled',
                    autoSlideDefaultDuration = 5
                }) {
        super({
            slideSelector: slideSelector,
            context: context,
            selector: selector,
            config: config,
            status: status,
            autoSlideDefaultDuration: autoSlideDefaultDuration,
            progressLoopDuration: 0
        });
        // this.config.async = true;
        this.config.async = false;
        this.disabledClass = disabledClass;
    }


    setSectionActions(sectionActions) {
        this.sectionActions = sectionActions;
    }


    prepare(element, done) {
        // to be called when the first slide is ready
        this.firstDone = done;
        this.allLoadListener = this.events.on(element, 'slideshow:load', this.onSlideshowLoad.bind(this));
        this.prevListener = this.events.on(element, this.dataSelector('slideshowPrev'), 'click', this.onPrevClick.bind(this));
        this.nextListener = this.events.on(element, this.dataSelector('slideshowNext'), 'click', this.onNextClick.bind(this));
        this.swipeListener = this.events.on(this.element, this.dataSelector('slide'), 'swipe', this.onSwipe.bind(this));
        super.prepare(element, () => {
        });
    }


    clear(done) {
        this.allLoadListener.destroy();
        this.prevListener.destroy();
        this.nextListener.destroy();
        this.swipeListener.destroy();
        super.clear(done);
    }


    onSlideshowLoad(event) {
        this.enableButtons();
        this.startAutoSlide();
    }


    onPrevClick(event) {
        event.preventDefault();
        this.stopAutoSlide();
        this.prev();
    }


    onNextClick(event) {
        event.preventDefault();
        this.stopAutoSlide();
        this.next();
    }


    onSwipe(event) {
        event.preventDefault();
        if (event.gesture.offsetDirection === this.events.gestures.DIRECTION_RIGHT) {
            this.prev();
        } else {
            this.next();
        }
    }


    onSlideLoad(event, target) {
        // console.log(event.detail);
        if (event.detail.index === 0) {
            if (this.firstDone) {
                this.firstDone();
            }
        }
        super.onSlideLoad(event, target);
    }


    enableButtons() {
        return this.toggleButtons(true);
    }


    disableButton() {
        return this.toggleButtons(false);
    }


    toggleButtons(value) {
        this.classList(this.element).toggle(this.disabledClass, !value);
        return this;
    }

}


export default BasicSlideshow;
