import DefaultPage from './default-page';

class ExhibitionPage extends DefaultPage {

	prepare(element, done) {
		super.prepare(element, () => {
			this.addNewComponent('ExclusionMenu');
			if (element.querySelector(this.dataAttr().getSelector('menu-second'))) {
				this.addNewComponent('ContentShifter');
			}
			const videos = element.querySelectorAll(this.dataSelector('video'));
			for (const video of videos) {
				this.addNewComponent('artistVideo').setElement(video);
			}
			this.addNewComponent('readMore');
			done();
		});
	}

}

export default ExhibitionPage;
