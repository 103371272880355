class ClassList {

	constructor(element = null, prefix = '') {
		this.element = element;
		this.prefix = prefix;
	}


	setElement(element) {
		this.element = element;
		return this;
	}


	setPrefix(prefix) {
		this.prefix = prefix;
		return this;
	}


	getPrefix(prefix) {
		return this.prefix;
	}


	getSelector(name) {
		return '.' + this.prefix + name;
	}


	addPrefix(classes) {
		for (let i = 0, end = classes.length; i < end; i++) {
			classes[i] = this.prefix + classes[i];
		}
		return classes;
	}


	removePrefix(className) {
		if (className.substr(0, this.prefix.length) === this.prefix) {
			className = className.substr(this.prefix.length);
		}
	}


	add(...classes) {
		if (this.prefix) {
			classes = this.addPrefix(classes);
		}
		this.element.classList.add(...classes);
	}


	remove(...classes) {
		if (this.prefix) {
			classes = this.addPrefix(classes);
		}
		this.element.classList.remove(...classes);
	}


	item(number) {
		let item = this.element.classList.item(number);
		if (item && this.prefix) {
			item = this.removePrefix(item);
		}
		return item;
	}


	toggle(className, force) {
		if (this.prefix) {
			className = this.addPrefix([className])[0];
		}
		if (force !== undefined) {
			this.element.classList.toggle(className, force);
		} else {
			this.element.classList.toggle(className);
		}
	}


	contains(className) {
		if (this.prefix) {
			className = this.addPrefix([className])[0];
		}
		return this.element.classList.contains(className);
	}
}

const classList = (element = null, prefix = false) => new ClassList(element, prefix !== false ? prefix : classList.prefix);
classList.prefix = false;


export {ClassList, classList};
