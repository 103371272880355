class PageSlotManager {

    constructor({pageSlotFactory}) {
		this.pageSlotFactory = pageSlotFactory;
        this.slots = new Map();
    }


	addPageSlot(slot) {
		this.slots.set(slot.getName(), slot);
		return this;
	}


	addNewPageSlot(name, params = {}) {
		const slot = this.pageSlotFactory.newInstance(name, params);
		this.addPageSlot(slot);
		return slot;
	}


	has(name) {
		return this.slots.has(name);
	}



	get(name) {
		if (this.slots.has(name)) {
			return this.slots.get(name);
		}
		return null;
	}

}


export default PageSlotManager;
