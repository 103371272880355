export function ucFirst(value) {
    return value.substr(0, 1).toUpperCase() + value.substr(1);
}


export function decamelize(value, separator = '-') {
    return value.replace(/([a-z0-9]+)([A-Z]+)/g, '$1' + separator + '$2').toLowerCase();
}


export function camelize(value, setUcFirst = false) {
    value = value.replace(/[-_]+([a-z0-9])/gi, (all, letter) => (letter.toUpperCase()));
    return (setUcFirst? ucFirst(value) : value);
}


export function trimUrl(url) {
    if (url.lastIndexOf('/') === url.length - 1) {
        url = url.substr(0, url.length - 1);
    }
    return url;
}
