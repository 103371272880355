import {TweenLite} from 'gsap';
import PageComponent from '../../global/page/page-component';


class ReadMore extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {}, animationDuration} = {}) {
		super({context: context, selector: selector, config: config, status: status});
        this.animationDuration = animationDuration;
	}


    prepare(element, done) {
        this.moreText = element.querySelector(this.dataSelector('readmore-txt'));
        this.moreBtn = element.querySelector(this.dataSelector('readmore-btn'));
        this.clickListener = this.events.on(this.element, this.dataSelector('readmore-btn'), 'click', this.onClickListener.bind(this));
        done();
    }


    clear(done) {
        this.clickListener.destroy();
        done();
    }


    onClickListener(event) {
		TweenLite.set(this.moreText, {height: 'auto', display: 'block'});
		TweenLite.from(this.moreText, this.animationDuration, {height: 0});
		TweenLite.to(this.moreBtn, this.animationDuration / 2, {height: 0});

		event.preventDefault();
		this.clickListener.destroy();
    }
}


export default ReadMore;
