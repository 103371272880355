import PageComponent from '../../global/page/page-component';
import {deaccent} from '../utils/string';


class SearchExhibitions extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {}, animationDuration} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.animationDuration = animationDuration;
		this.exhibitionsHeaderDataSelector = 'exhibitions-header';
		this.exhibitionsYearDataSelector = 'exhibitions-year';
		this.exhibitionsCityDataSelector = 'exhibitions-city';
		this.searchElemDataSelector = 'exhibition-search';
		this.maxIterations = 20;
		this.disabledClass = 'hidden';
	}


	prepare(element, done) {
		this.search = element.querySelector(this.dataSelector('search'));
		this.emptySearchText = element.querySelector(this.dataSelector('exhibitions-empty'));

		this.headers = [];
		let currentHeader = false;
		for (const exhibitionsHeaderOrYearNode of element.querySelectorAll(this.dataSelector(this.exhibitionsYearDataSelector) + ',' + this.dataSelector(this.exhibitionsHeaderDataSelector))) {
			if (this.dataAttr(exhibitionsHeaderOrYearNode).has(this.exhibitionsHeaderDataSelector)) {
				currentHeader = {
					headerNode: exhibitionsHeaderOrYearNode,
					yearNodes: [],
					yearRefs: [],
					visible: true,
				};
				this.headers.push(currentHeader);
			} else {
				if (currentHeader) {
					currentHeader.yearNodes.push(exhibitionsHeaderOrYearNode);
				}
			}
		}


		this.exhibitions = [];
		for (const exhibitionsYearNode of element.querySelectorAll(this.dataSelector(this.exhibitionsYearDataSelector))) {
			const year = this.dataAttr(exhibitionsYearNode).get(this.exhibitionsYearDataSelector);
			const exhibitionsYearObj = {
				year: year,
				visible: true,
				node: exhibitionsYearNode,
				cities: [],
				headerRef: null
			};

			for (const header of this.headers) {
				if (header.yearNodes.includes(exhibitionsYearNode)) {
					exhibitionsYearObj.headerRef = header;
					header.yearRefs.push(exhibitionsYearObj);
				}
			}

			for (const exhibitionsCityNode of exhibitionsYearNode.querySelectorAll(this.dataSelector(this.exhibitionsCityDataSelector))) {
				const city = this.dataAttr(exhibitionsCityNode).get(this.exhibitionsCityDataSelector);
				if (exhibitionsCityNode) {
					exhibitionsYearObj.cities[city] = [];
					const exhibitionsCityObj = {
						city: city,
						visible: true,
						node: exhibitionsCityNode,
						items: []
					};
					for (const exhibition of exhibitionsCityNode.querySelectorAll(this.dataSelector('exhibition'))) {
						const searchText = exhibition.querySelector(this.dataSelector(this.searchElemDataSelector));
						const text = this.dataAttr(searchText).get(this.searchElemDataSelector);
						exhibitionsCityObj.items.push({
							search: text,
							visible: true,
							node: exhibition
						});
					}
					exhibitionsYearObj.cities.push(exhibitionsCityObj);
				}
			}
			this.exhibitions.push(exhibitionsYearObj);
		}

		this.inputListener = this.events.on(this.element, this.dataSelector('search'), 'input', this.onInputListener.bind(this));

		done();
	}


	clear(done) {
		this.inputListener.destroy();
		done();
	}


	onInputListener(event) {
		this.filterExhibitions();
		event.preventDefault();
	}

	filterExhibitions() {
		const searchTerms = deaccent(this.search.value.replace(/\s\s+/g, ' ')).trim().toLowerCase().split(' ');
		this.changedTotal = 0;

		for (const exhibitionYear of this.exhibitions) {
			exhibitionYear.visible = false;
			for (const exhibitionCity of exhibitionYear.cities) {
				//TODO: city visible?
				exhibitionCity.visible = false;
				for (const exhibition of exhibitionCity.items) {
					const visible = this.isVisible(exhibition.search, searchTerms);
					exhibitionCity.visible = exhibitionCity.visible || visible;
					exhibitionYear.visible = exhibitionYear.visible || visible;
					if (visible !== exhibition.visible) {
						this.changedTotal++;
						exhibition.visible = visible;
						exhibition.updated = false;
					}
				}
			}
		}

		if (this.changedTotal > 0) {
			this.updateExhibition();
		}
	}


	isVisible(search, searchTerms) {
		for (const searchTerm of searchTerms) {
			if (search.indexOf(searchTerm) === -1) {
				return false;
			}
		}
		return true;
	}

	updateExhibition() {
		let yearIndex = 0;
		let cityIndex = 0;
		let exhiIndex = 0;
		let hiddnExhibitionYears = 0;



		for (const header of this.headers) {
			header.visible = false;
			for (const year of header.yearRefs) {
				header.visible = header.visible || year.visible;
			}
			this.classList(header.headerNode).toggle(this.disabledClass, !header.visible);
		}

		const callback = () => {
			let iterations = 0;
			for (; yearIndex < this.exhibitions.length; yearIndex++) {
				const exhibitionYear = this.exhibitions[yearIndex];
				this.classList(exhibitionYear.node).toggle(this.disabledClass, !exhibitionYear.visible);
				if (exhibitionYear.visible) {
					for (; cityIndex < exhibitionYear.cities.length; cityIndex++) {
						const exhibitionCity = exhibitionYear.cities[cityIndex];
						this.classList(exhibitionCity.node).toggle(this.disabledClass, !exhibitionCity.visible);
						if (exhibitionCity.visible) {
							for (; exhiIndex < exhibitionCity.items.length; exhiIndex++) {
								iterations++;
								const exhibition = exhibitionCity.items[exhiIndex];
								this.classList(exhibition.node).toggle(this.disabledClass, !exhibition.visible);
								if (iterations >= this.maxIterations) {
									this.searchFrame = requestAnimationFrame(callback);
									return;
								}
							}
						}
						exhiIndex = 0;
					}
					cityIndex = 0;
				} else {
					hiddnExhibitionYears++;
				}
			}
			this.classList(this.emptySearchText).toggle('visible', (hiddnExhibitionYears === this.exhibitions.length));
		};

		if (this.searchFrame) {
			cancelAnimationFrame(this.searchFrame);
		}
		callback();
	}
}


export default SearchExhibitions;
