import {str_replace as strReplace} from 'locutus/php/strings';

export function deaccent(text, caze = 0) {
	/* INFO: from php- 2 js-format
		search:
			'([\W])'\s=>\s'
		replace:
			$1: '
	*/
	const UTF8_LOWER_ACCENTS = {
		à: 'a', ô: 'o', ď: 'd', ḟ: 'f', ë: 'e', š: 's', ơ: 'o',
		ß: 'ss', ă: 'a', ř: 'r', ț: 't', ň: 'n', ā: 'a', ķ: 'k',
		ŝ: 's', ỳ: 'y', ņ: 'n', ĺ: 'l', ħ: 'h', ṗ: 'p', ó: 'o',
		ú: 'u', ě: 'e', é: 'e', ç: 'c', ẁ: 'w', ċ: 'c', õ: 'o',
		ṡ: 's', ø: 'o', ģ: 'g', ŧ: 't', ș: 's', ė: 'e', ĉ: 'c',
		ś: 's', î: 'i', ű: 'u', ć: 'c', ę: 'e', ŵ: 'w', ṫ: 't',
		ū: 'u', č: 'c', ö: 'o', è: 'e', ŷ: 'y', ą: 'a', ł: 'l',
		ų: 'u', ů: 'u', ş: 's', ğ: 'g', ļ: 'l', ƒ: 'f', ž: 'z',
		ẃ: 'w', ḃ: 'b', å: 'a', ì: 'i', ï: 'i', ḋ: 'd', ť: 't',
		ŗ: 'r', ä: 'a', í: 'i', ŕ: 'r', ê: 'e', ü: 'u', ò: 'o',
		ē: 'e', ñ: 'n', ń: 'n', ĥ: 'h', ĝ: 'g', đ: 'd', ĵ: 'j',
		ÿ: 'y', ũ: 'u', ŭ: 'u', ư: 'u', ţ: 't', ý: 'y', ő: 'o',
		â: 'a', ľ: 'l', ẅ: 'w', ż: 'z', ī: 'i', ã: 'a', ġ: 'g',
		ṁ: 'm', ō: 'o', ĩ: 'i', ù: 'u', į: 'i', ź: 'z', á: 'a',
		û: 'u', þ: 'th', ð: 'dh', æ: 'ae', µ: 'u'
	};
	const UTF8_UPPER_ACCENTS = {
		À: 'A', Ô: 'O', Ď: 'D', Ḟ: 'F', Ë: 'E', Š: 'S', Ơ: 'O',
		Ă: 'A', Ř: 'R', Ț: 'T', Ň: 'N', Ā: 'A', Ķ: 'K',
		Ŝ: 'S', Ỳ: 'Y', Ņ: 'N', Ĺ: 'L', Ħ: 'H', Ṗ: 'P', Ó: 'O',
		Ú: 'U', Ě: 'E', É: 'E', Ç: 'C', Ẁ: 'W', Ċ: 'C', Õ: 'O',
		Ṡ: 'S', Ø: 'O', Ģ: 'G', Ŧ: 'T', Ș: 'S', Ė: 'E', Ĉ: 'C',
		Ś: 'S', Î: 'I', Ű: 'U', Ć: 'C', Ę: 'E', Ŵ: 'W', Ṫ: 'T',
		Ū: 'U', Č: 'C', Ö: 'O', È: 'E', Ŷ: 'Y', Ą: 'A', Ł: 'L',
		Ų: 'U', Ů: 'U', Ş: 'S', Ğ: 'G', Ļ: 'L', Ƒ: 'F', Ž: 'Z',
		Ẃ: 'W', Ḃ: 'B', Å: 'A', Ì: 'I', Ï: 'I', Ḋ: 'D', Ť: 'T',
		Ŗ: 'R', Ä: 'A', Í: 'I', Ŕ: 'R', Ê: 'E', Ü: 'U', Ò: 'O',
		Ē: 'E', Ñ: 'N', Ń: 'N', Ĥ: 'H', Ĝ: 'G', Đ: 'D', Ĵ: 'J',
		Ÿ: 'Y', Ũ: 'U', Ŭ: 'U', Ư: 'U', Ţ: 'T', Ý: 'Y', Ő: 'O',
		Â: 'A', Ľ: 'L', Ẅ: 'W', Ż: 'Z', Ī: 'I', Ã: 'A', Ġ: 'G',
		Ṁ: 'M', Ō: 'O', Ĩ: 'I', Ù: 'U', Į: 'I', Ź: 'Z', Á: 'A',
		Û: 'U', Þ: 'Th', Ð: 'Dh', Æ: 'Ae'
	};

	if(caze <= 0) {
		text = strReplace(Object.keys(UTF8_LOWER_ACCENTS), Object.values(UTF8_LOWER_ACCENTS), text);
	}
	if(caze >= 0) {
		text = strReplace(Object.keys(UTF8_UPPER_ACCENTS), Object.values(UTF8_UPPER_ACCENTS), text);
	}
	return text;
}
