import PageComponent from '../page/page-component';


class LoaderAnimation extends PageComponent {

    play(done = () => {}, options = {}) {
        done();
    }


    pause(done = () => {}, options = {}) {
        done();
    }

}

export default LoaderAnimation;
