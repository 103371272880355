import asq from 'asynquence';
import PageComponentCollection from '../page/page-component-collection';


class App extends PageComponentCollection {
	constructor({config = {}, status = {}, requiredStyles = [], requiredFonts = []}) {
		super({config: config, status: status});
		this.requiredStyles = requiredStyles;
		this.requiredFonts = requiredFonts;

		this.fontsLoadedCallbacks = [];
		this.fontsLoadedCount = 0;
	}


	setFontChecker(fontChecker) {
		this.fontChecker = fontChecker;
	}


	setStyleChecker(styleChecker) {
		this.styleChecker = styleChecker;
	}


	setNavigation(navigation) {
		this.navigation = navigation;
	}


	setPageManager(pageManager) {
		this.pageManager = pageManager;
	}


	init(done) {
		const loadingCallbacks = [];
        for (const style of this.requiredStyles) {
            loadingCallbacks.push((localDone) => {
                this.styleLoaded(style, localDone);
            });
        }
        for (const font of this.requiredFonts) {
            loadingCallbacks.push((localDone) => this.fontLoaded(font.name, font.options || {}, localDone));
        }

		loadingCallbacks.push((localDone) => {
			super.init(document.body, localDone);
		});
		// const superInit = () => super.init(document.body, done);

		// if (loadingCallbacks.length) {
			asq().all(...loadingCallbacks).then(done);
		// } else {
		// 	superInit();
		// }
	}


	fontLoaded(name, options, done) {
        this.fontChecker.checkFont(name, options, () => {
			this.fontsLoadedCount++;
			if (this.fontsLoadedCount === this.requiredFonts.length) {
				this.processFontsCallbacks();
			}
			done();
		});
    }


    styleLoaded(name, done) {
        this.styleChecker.checkStyle(name, done);
    }


	addFontsLoadedCallback(callback) {
		if (this.fontsLoadedCount === this.requiredFonts.length) {
			callback();
		} else {
			this.fontsLoadedCallbacks.push(callback);
		}
		return this;
	}


	processFontsCallbacks() {
		for (const callback of this.fontsLoadedCallbacks) {
			callback();
		}
		this.fontsLoadedCallbacks = [];
	}

}


export default App;
