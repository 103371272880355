//import asq from 'asynquence';
import Page from '../../global/page/page';


class DefaultPage extends Page {

	prepare(element, done) {
		super.prepare(element, () => {
			this.addNewComponent('Pictures');
			this.addNewComponent('Videos');
			done();
		});
	}

}

export default DefaultPage;
