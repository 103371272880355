import PageComponent from '../../global/page/page-component';


class ExclusionMenu extends PageComponent {


    prepare(element, done) {
        this.current = null;
        this.mouseClickHandler = this.events.on(this.element, this.dataSelector('menuTrigger'), 'click', this.onClick.bind(this));
		this.isOpen = false;
        done();
    }

    clear(done) {
        this.current = null;
        this.mouseClickHandler.destroy();
        done();
    }

	onClick(event, target) {
		event.preventDefault();
		const index = this.dataAttr(target).get('menuTrigger');

		if (index !== this.current) {
			this.current = index;
			this.out();
			this.over(index);
		} else {
			this.out();
			this.current = null;
		}
	}


    over(index) {
        this.classList(this.element).add('menuSelected');
		for (const element of this.element.querySelectorAll(this.dataSelector('menuTarget', index))) {
			this.classList(element).add('menuSelected');
		}
    }

	out() {
		this.classList(this.element).remove('menuSelected');
		for (const element of this.element.querySelectorAll('.' + this.classPrefix + 'menuSelected')) {
			this.classList(element).remove('menuSelected');
		}
    }

}

export default ExclusionMenu;
