import asq from 'asynquence';
import onTransitionEnd from '../utils/transition-end';
import pageInteractionMixin from '../utils/page-interaction-mixin';


class SlideAnimator extends pageInteractionMixin() {

    constructor({
        beforeInClass = 'beforeIn',
        beforeOutClass = 'beforeOut',
        currentClass = 'current',
    } = {}) {
		super();
        this.beforeInClass = beforeInClass;
        this.beforeOutClass = beforeOutClass;
        this.currentClass = currentClass;
    }


    switch(currentSlide, newSlide, allDone) {
        asq()
            .then((done) => {
                this.beforeSwitchIn(newSlide, done);
            })
            .then((done) => {
                this.beforeSwitchOut(currentSlide, done);
            })
            .then((done) => {
                this.animate(currentSlide, newSlide, done);
                currentSlide.setCurrent(false);
                newSlide.setCurrent(true);
            })
            .then((done) => {
                this.afterSwitchIn(newSlide, done);
            })
            .then((done) => {
                this.afterSwitchOut(currentSlide, done);
            })
            .then((done) => {
                allDone();
                done();
            });
        return this;
    }


    beforeSwitchIn(slide, done) {
        slide.beforeSwitchIn(() => {
            this.classList(slide.getElement()).add(this.beforeInClass);
            done();
        });
    }


    beforeSwitchOut(slide, done) {
        slide.beforeSwitchOut(() => {
            this.classList(slide.getElement()).add(this.beforeOutClass);
            done();
        });
    }


    animate(currentSlide, newSlide, allDone) {
        asq()
            .all(
                (done) => {
                    const currentElement = currentSlide.getElement();
                    this.classList(currentElement).remove(this.currentClass);
                    onTransitionEnd(currentElement, done);
                },
                (done) => {
                    const newElement = newSlide.getElement();
                    this.classList(newElement).add(this.currentClass);
                    onTransitionEnd(newElement, done);
                }
            )
            .then((done) => {
                allDone();
                done();
            });
        return this;
    }


    afterSwitchIn(slide, done) {
        slide.afterSwitchIn(() => {
            this.classList(slide.getElement()).remove(this.beforeInClass);
            done();
        });
    }



    afterSwitchOut(slide, done) {
        slide.afterSwitchOut(() => {
            this.classList(slide.getElement()).remove(this.beforeOutClass);
            done();
        });
    }

}


export default SlideAnimator;
