import pageComponentCollectionMixin from '../page/page-component-collection-mixin';
import pageInteractionMixin from '../utils/page-interaction-mixin';


class Context extends pageComponentCollectionMixin(pageInteractionMixin()) {

    constructor({name, selector}) {
		super();
        this.name = name;
        this.selector = selector;
        this.element = null;
        this.contextManager = null;
		this.active = false;
    }


	setContextManager(manager) {
        this.contextManager = manager;
        return this;
    }


	getElement() {
        if (!this.element || !document.body.contains(this.element)) {
            this.element = document.querySelector(this.selector);
        }
        return this.element;
    }


	setElement(element) {
		this.element = element;
		return this;
	}


    getName() {
        return this.name;
    }


	activate(force = false) {
		if (!this.active || force) {
			this.active = true;
			this.activateComponents(force);
			this.events.trigger(this.getElement(), 'context:activate', {context: this});
		}
		return this;
	}


	deactivate() {
		if (this.active) {
			this.active = false;
			this.deactivateComponents();
			this.events.trigger(this.getElement(), 'context:deactivate', {context: this});
		}
		return this;
	}


	isActive() {
		return this.active;
	}


    beforeSwitchIn(done, otherContext) {
        this.events.trigger(this.getElement(), 'context:beforeswitchin', {context: this, otherContext: otherContext});
        done();
    }


    beforeSwitchOut(done, otherContext) {
        this.events.trigger(this.getElement(), 'context:beforeswitchout', {context: this, otherContext: otherContext});
        done();
    }


    switchIn(done, otherContext) {
        this.events.trigger(this.getElement(), 'context:switchin', {context: this, otherContext: otherContext});
        done();
    }


    switchOut(done, otherContext) {
        this.events.trigger(this.getElement(), 'context:switchout', {context: this, otherContext: otherContext});
        done();
    }


    afterSwitchIn(done, otherContext) {
        this.events.trigger(this.getElement(), 'context:afterswitchin', {context: this, otherContext: otherContext});
        done();
    }


    afterSwitchOut(done, otherContext) {
        this.events.trigger(this.getElement(), 'context:afterswitchout', {context: this, otherContext: otherContext});
        done();
    }
}


export default Context;
