import DefaultPage from './default-page';

class NewsPage extends DefaultPage {

	prepare(element, done) {
		super.prepare(element, () => {
			this.addNewComponent('Accordion');
			done();
		});
	}
}

export default NewsPage;
