import asq from 'asynquence';
import {getScrollTop} from 'get-scroll';
import {isObject} from '../utils/types';
import pageInteractionMixin from '../utils/page-interaction-mixin';



class PageTransition extends pageInteractionMixin() {

	constructor() {
		super();
		this.event = null;
		this.request = null;
		this.response = null;
	}


	setHistory(history) {
		this.history = history;
	}


	setPageLoader(pageLoader) {
		this.pageLoader = pageLoader;
	}


	setContextManager(contextManager) {
		this.contextManager = contextManager;
	}


	match(event) {
		return true;
	}


	init(event) {
		this.event = event;
		this.request = event.detail;
		if (this.request.navigationType === 'link') {
			this.history.mergeState({title: document.title});
		}
		return this;
	}


	start(done) {
		const sequence = asq();

		let newPages;
		sequence
			.then((stepDone) => this.beginAnimation(stepDone))
			.then((stepDone) => {
				this.pageLoader.load(this.request, (pages, response) => {
					if (this.request.navigationType === 'link') {
						this.storeScrollPos();
						this.updateHistory();
					} else {
						this.updateCurrentHistory();
					}
					newPages = pages;
					this.response = response;
					const pagesSequence = asq();
					for (const newPage of newPages) {
						pagesSequence
							.then((pageDone) => this.attachNewPage(newPage, pageDone))
							.then((pageDone) => this.deInitCurrentPage(newPage, pageDone))
							.then((pageDone) => this.initNewPage(newPage, pageDone))
							.then((pageDone) => this.finalizeTransition(newPage, pageDone))
						;
					}
					pagesSequence
						.then(stepDone)
					;
				});
			})
			.then((stepDone) => this.commit(stepDone))
			.then((stepDone) => this.endAnimation(stepDone))
			.then((stepDone) => this.activatePages(newPages, stepDone))
			.then(() => this.reset(done))
		;
	}


	attachNewPage(newPage, done) {
		newPage.attach();
		done();
	}


	deInitCurrentPage(newPage, done) {
		const oldPage = newPage.getPageSlot().getCurrentPage();
		if (oldPage) {
			oldPage.deinit(done);
		} else {
			done();
		}
	}


	initNewPage(newPage, done) {
		const pageElement = newPage.getElement();
		newPage.init(pageElement, done);
	}


	activatePages(newPages, done) {
		for (const newPage of newPages) {
			newPage.activate();
		}
		done();
	}


	finalizeTransition(newPage, done) {
		newPage.setAsCurrent();
		done();
	}


	commit(done) {
		this
			.updateScrollPos()
			.storeScrollPos()
			.updateDocumentTitle()
			.raisePageChangeEvent()
			.raiseHashChangeEvent()
		;
		done();
		return this;
	}


	raisePageChangeEvent() {
		this.events.trigger(document, 'history:pagechange', {url: location.href, title: document.title});
		return this;
	}


	raiseHashChangeEvent() {
		if (this.event.detail.hash !== null) {
			this.events.trigger(document, 'history:hashchange', {hash: this.event.detail.hash});
		}
		return this;
	}


	reset(done) {
		this.event = null;
		this.request = null;
		this.response = null;
		done();
		return this;
	}


	updateHistory() {
		let url = this.request.url;
		if (this.event.detail.hash !== null) {
			url += '#' + this.event.detail.hash;
		}

		const title = isObject(this.response) && 'title' in this.response ? this.response.title : null;
		this.history.push(url, {title: title}, title);
		return this;
	}


	updateCurrentHistory() {
		return this;
	}


	updateScrollPos() {
		window.scrollTo(0, (this.request.state && ('scrollTop' in this.request.state))? this.request.state.scrollTop : 0);
		return this;
	}


	storeScrollPos() {
		this.history.mergeState({scrollTop: getScrollTop()});
		return this;
	}


	updateDocumentTitle() {
		if (isObject(this.response) && 'title' in this.response) {
			document.title = this.response.title;
		} else if (this.request.navigationType === 'pop' && 'title' in this.request.state) {
			document.title = this.request.state.title;
		}
		return this;
	}

	beginAnimation(done) {
		done();
	}


	endAnimation(done) {
		done();
	}

}


export default PageTransition;
