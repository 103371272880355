import PageComponentCollection from './page-component-collection';
import {isString} from '../utils/types';


class Page extends PageComponentCollection {
	constructor({pageSlot = null, config = {}, status = {}}) {
		super({context: null, config: config, status: status});
		this.pageSlot = pageSlot;
	}


	setPageSlotManager(pageSlotManager) {
		this.pageSlotManager = pageSlotManager;
		if (this.pageSlot && isString(this.pageSlot)) {
			this.pageSlot = this.pageSlotManager.get(this.pageSlot);
		}
	}


	attach() {
		this.pageSlot.attach(this.getElement());
		return this;
	}


	setAsCurrent() {
		this.pageSlot.setCurrentPage(this);
		return this;
	}


	getPageSlot() {
		return this.pageSlot;
	}

}


export default Page;
