import asq from 'asynquence';


const pageComponentCollectionMixin = (Base = null) => class extends Base {

	setComponentFactory(componentFactory) {
		this.componentFactory = componentFactory;
		return this;
	}

	initCollection() {
		if (!this.components) {
			this.components = new Set();
		}
		return this;
	}


	addComponent(component) {
		this.initCollection();
		this.components.add(component);
		return this;
	}


	addNewComponent(name, params) {
		const component = this.componentFactory.newInstance(name, params);
		this.addComponent(component);
		return component;
	}


	removeComponent(component) {
		this.initCollection();
		this.components.delete(component);
		return this;
	}


	getComponents() {
		this.initCollection();
		return this.components;
	}


	count() {
		this.initCollection();
		return this.components.size;
	}


	get length() {
		this.initCollection();
		return this.components.size;
	}


	initComponents(done) {
		this.initCollection();
		const sequence = asq();
		for (const component of this.components.values()) {
			const selector = component.getSelector();
			const element = selector === true ? this.element : (selector.length ? this.element.querySelector(selector) : null);
			if (component.isAsync()) {
				sequence.then((componentDone) => component.init(element, componentDone));
			} else {
				component.init(element);
			}
		}
		sequence.then(() => done());
		return this;
	}


	deinitComponents(done) {
		this.initCollection();
		const sequence = asq();
		for (const component of this.components.values()) {
			if (component.isAsync()) {
				sequence.then((componentDone) => component.deinit(componentDone));
			} else {
				component.deinit();
			}
		}
		sequence.then(() => done());
		return this;
	}


	activateComponents(force = false) {
		this.initCollection();
		for (const component of this.components.values()) {
			component.activate(force);
		}
		return this;
	}


	deactivateComponents() {
		this.initCollection();
		for (const component of this.components.values()) {
			component.deactivate();
		}
		return this;
	}

};


export default pageComponentCollectionMixin;
